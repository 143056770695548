import { ApiEndpoints, FacilityLicense } from 'models';
import { standardApi } from 'store/api';

import { ShortOption } from './FilterService';

import type { Facility, FieldMapping, RecordType } from 'models/models';

export interface CreateFacilityRequest {
	healthsystem_id: number;
	name: string;
	display_name: string;
	license_type: string;
	street_address?: string | null;
	city?: string | null;
	state?: string | null;
	zip?: number | null;
	ehr?: string | null;
	cms_designation?: string | null;
	number_of_beds?: number | null;
	clone_facility_id?: number;
	field_mappings: FieldMapping;
	rooms: { id?: number | null; name: string; licensed: boolean }[];
	schedule_record_types?: RecordType[];
}

export interface SetFacilityConfigurationRequest extends CreateFacilityRequest {
	reset_filters: boolean;
	facility_id: number;
}

export interface SetFacilityConfigurationResponse {
	facility: Facility;
}

export interface CreateFacilityResponse {
	facility_id: number;
	facility_name: string;
	rooms_added: { id: number; name: string; licensed: boolean }[];
}

export interface SetAnesthesiaFacilitySettingRequest {
	facility_id: number | undefined;
	crna_vacation_weeks: number;
	mda_vacation_weeks: number;
	crna_weekly_clinical_hours: number;
	mda_weekly_clinical_hours: number;
	medical_direction_model: string;
}

export interface SetNurseStaffingFacilitySettingRequest {
	facility_id: number | undefined;
	rn_vacation_weeks: number;
	surgical_tech_vacation_weeks: number;
	variable_staff_vacation_weeks: number;
	indirect_staff_vacation_weeks: number;
	rn_weekly_clinical_hours: number;
	surgical_tech_weekly_clinical_hours: number;
	variable_staff_weekly_clinical_hours: number;
	indirect_staff_weekly_clinical_hours: number;
	productivity_lower_bound: number;
	productivity_upper_bound: number;
}

export interface SetFacilitySettingRequest {
	holiday_list_append?: boolean;
	facility_id: number;
	fcots_target: number;
	addon_rate_target: number;
	facility_utilization_target: number;
	turnover_target: number;
	fcots_timestamp: string;
	fcots_window_time_start: string;
	fcots_window_time_end: string;
	sunday_rooms_count: number;
	monday_rooms_count: number;
	tuesday_rooms_count: number;
	wednesday_rooms_count: number;
	thursday_rooms_count: number;
	friday_rooms_count: number;
	saturday_rooms_count: number;
	sunday_prime_time_start: string | undefined;
	sunday_prime_time_end: string | undefined;
	monday_prime_time_start: string;
	monday_prime_time_end: string;
	tuesday_prime_time_start: string;
	tuesday_prime_time_end: string;
	wednesday_prime_time_start: string;
	wednesday_prime_time_end: string;
	thursday_prime_time_start: string;
	thursday_prime_time_end: string;
	friday_prime_time_start: string;
	friday_prime_time_end: string;
	saturday_prime_time_start: string | undefined;
	saturday_prime_time_end: string | undefined;
	is_primetime_strict?: boolean;
	planned_setup_time?: number;
	planned_cleanup_time?: number;
}

export interface SetFacilitySettingResponse {
	pipeline_rerun_required: boolean;
}

export interface FacilitySettingStatusResponse {
	run_completed: boolean;
	date_of_run: Date | null;
	run_exists: boolean;
}

export interface SetArtificialFacility {
	healthsystem_id: number | null;
	name: string;
	children: number[];
	previous_facility_settings?: any;
}

interface DeleteArtificialFacility {
	facility_id: number | undefined;
	refresh_cache: boolean;
}

const apiWithTags = standardApi.enhanceEndpoints({
	addTagTypes: ['Core', 'FacilityConfig', 'FacilitySettings', 'System', 'Anesthesia', 'DailyHuddle'],
});

/**
 * Provides endpoints for setting values in database
 */
export const facilityService = apiWithTags.injectEndpoints({
	endpoints: (build) => ({
		setFacilityConfig: build.mutation<SetFacilityConfigurationResponse, SetFacilityConfigurationRequest>({
			query: (body) => ({
				url: ApiEndpoints.SET_FACILITY_CONFIG,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Core', 'FacilityConfig'],
		}),
		setArtificialFacility: build.mutation<SetFacilityConfigurationResponse, SetArtificialFacility>({
			query: (body) => ({
				url: ApiEndpoints.SET_ARTIFICIAL_FACILITY,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Core', 'FacilityConfig'],
		}),
		deleteArtificialFacility: build.mutation<{ children: number[]; last_settings?: any }, DeleteArtificialFacility>({
			query: (body) => ({
				url: ApiEndpoints.DELETE_ARTIFICIAL_FACILITY,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) => (arg.refresh_cache ? ['Core', 'FacilityConfig', 'System'] : []),
		}),
		addFacility: build.mutation<CreateFacilityResponse, CreateFacilityRequest>({
			query: (body) => ({
				url: ApiEndpoints.CREATE_FACILITY,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Core', 'FacilityConfig'],
		}),
		setFacilitySetting: build.mutation<SetFacilitySettingResponse, SetFacilitySettingRequest>({
			query: (body) => ({
				url: ApiEndpoints.SET_FACILITY_SETTING,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) =>
				arg.holiday_list_append ? [] : ['Core', 'FacilitySettings', 'System', 'DailyHuddle'],
		}),

		setAnesthesiaFacilitySetting: build.mutation<SetFacilitySettingResponse, SetAnesthesiaFacilitySettingRequest>({
			query: (body) => ({
				url: ApiEndpoints.SET_ANESTHESIA_FACILITY_SETTING,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Core', 'FacilitySettings', 'System', 'Anesthesia'],
		}),

		setNurseStaffingFacilitySetting: build.mutation<SetFacilitySettingResponse, SetNurseStaffingFacilitySettingRequest>({
			query: (body) => ({
				url: ApiEndpoints.SET_NURSE_STAFFING_FACILITY_SETTING,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Core', 'FacilitySettings', 'System'],
		}),

		getFacilitySettingStatus: build.mutation<
			FacilitySettingStatusResponse,
			{ facility_id: number | undefined; refetch_pages: boolean }
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_FACILITY_SETTING_STATUS,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) => (result?.run_completed && arg.refetch_pages ? ['Core'] : []),
		}),

		getFacilitySettingStatusInit: build.query<FacilitySettingStatusResponse, { facility_id: number | undefined }>({
			query: (body) => ({
				url: ApiEndpoints.GET_FACILITY_SETTING_STATUS,
				method: 'POST',
				body,
			}),
		}),
		getChildFacilities: build.query<
			{
				names: {
					value: number;
					label: string;
				}[];
			},
			{ facility_id: number | undefined }
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_ARTIFICIAL_FACILITY_CHILDREN,
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const {
	useSetFacilityConfigMutation,
	useAddFacilityMutation,
	useSetFacilitySettingMutation,
	useGetFacilitySettingStatusMutation,
	useGetFacilitySettingStatusInitQuery,
	useSetArtificialFacilityMutation,
	useDeleteArtificialFacilityMutation,
	useGetChildFacilitiesQuery,
	useSetAnesthesiaFacilitySettingMutation,
	useSetNurseStaffingFacilitySettingMutation,
} = facilityService;

export const {
	endpoints: {
		setFacilityConfig,
		addFacility,
		setFacilitySetting,
		getFacilitySettingStatus,
		getFacilitySettingStatusInit,
		setArtificialFacility,
		deleteArtificialFacility,
		setAnesthesiaFacilitySetting,
	},
} = facilityService;
