import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { faker } from '@faker-js/faker';
import CalendarHeatmap from 'react-calendar-heatmap';
import { addMonths, subMonths } from 'date-fns';
import { renderToStaticMarkup } from 'react-dom/server';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
	VictoryAxis,
	VictoryBar,
	VictoryBrushContainer,
	VictoryChart,
	VictoryLabel,
	VictoryLine,
	VictoryZoomContainer,
} from 'victory';

import {
	DashboardLinks,
	defaultCaseElements,
	defaultCaseElementsTemplate,
	FacilityLicense,
	Routes,
	UserRole,
} from 'models';
import {
	Accordion,
	Button,
	ChartLegend,
	Checkbox,
	DataTable,
	Dialog,
	MultiSelect,
	PageHeading,
	Select,
	TextArea,
	TextField,
	Tooltip,
} from 'components';
import {
	getQueryError,
	handleApiResponse,
	ScheduledCaseItem,
	SetScheduledCaseMetadata,
	useAddIntraopScheduleParentLinkMutation,
	useAppSelector,
	useGetDailyHuddleHeatmapQuery,
	useGetDashboardQuery,
	useGetDefaultStartEndDateQuery,
	useGetIntraopLinkStatusQuery,
	useGetIntraopNoteMapQuery,
	useGetIntraopNotesQuery,
	useGetIntraopScheduledCasesMeshedQuery,
	useGetIntraopScheduleMapQuery,
	useGetScheduledCaseElementsQuery,
	useGetScheduledCasesQuery,
	useGetScheduleLinkStatusQuery,
	useGetScheduleMetadataQuery,
	useGetSystemsQuery,
	useRemoveIntraopScheduleParentLinkMutation,
	useSetIntraopNotesMutation,
	useSetScheduledCaseElementsMutation,
	useSystem,
} from 'store';
import {
	checkLicenseBits,
	clamp,
	convertDateFormat,
	delay_reasons,
	getColor,
	getDatesBetween,
	numberWithCommas,
	toFullDate,
	truncateLabel,
} from 'utils';
import { useFilters, useToast } from 'context';
import { LoadingIndicator } from 'components/Select/subcomponents';
import { NotificationItemMinimal } from 'pages/Notifications';
import { adjustDatesForDST } from 'pages/Settings';
import { useGetNotificationInboxQuery } from 'store/services/NotificationService';

interface ScheduleRecord {
	id: number;
	procedure_description: string;
	surgeon_id: number;
	start: string;
	schedule_key?: string;
	last_selected_schedule_key?: string;
}

export interface ScheduleKeyMapItem {
	[key: string]: {
		date: string;
		room_id: number;
		surgeon_id: number;
		case_order: number;
		procedure: string;
		link_key: string;
	};
}

export interface IntraopNoteKeyMap {
	[key: number]: {
		delay_reason: string;
	};
}

export interface ScheduleMetadataMap {
	[key: string]: {
		metadata: SetScheduledCaseMetadata;
	};
}

interface MetaDataModuleProps {
	id: number;
	openState: boolean;
	date: string;
	room: string;
	surgeon: string;
	surgeon_id: number;
	room_id: number;
	start: string;
	currentIntraopSelectState?: boolean;
	setIntraopSelectState?: Dispatch<SetStateAction<boolean>>;
	selectedScheduleRecord?: ScheduleRecord;
	setSelectedScheduleRecord?: Dispatch<SetStateAction<ScheduleRecord>>;
	end: string;
	procedure: string;
	schedule_key: string;
	schedule_metadata: ScheduleMetadataMap;
	case_order: number;
}

interface HistoricalMetaDataModuleProps {
	id: number;
	surgeon_id: number;
	openState: boolean;
	start: string;
	currentIntraopSelectState: boolean;
	setIntraopSelectState: Dispatch<SetStateAction<boolean>>;
	selectedScheduleRecord: ScheduleRecord;
	setSelectedScheduleRecord: Dispatch<SetStateAction<ScheduleRecord>>;
	selectedIntraopRecords: number[];
	setSelectedIntraopRecords: Dispatch<SetStateAction<number[]>>;
	match_score?: number;
	procedure: string;
	schedule_key: string;
	room_id?: number;
	case_order?: number;
	date?: string;
	intraop_schedule_map: ScheduleKeyMapItem;
}

const today = new Date();
const endDate = addMonths(today, 3);
const startDate = subMonths(today, 4);

export function NotificationModule(props: { notifications: string[]; isLoading: boolean }) {
	const { data, refetch, isFetching } = useGetNotificationInboxQuery(undefined);

	useEffect(() => {
		refetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isLoading]);
	return (
		<div className='flex flex-col w-full h-100 bg-white'>
			<div className='bg-white font-semibold py-2 pt-0 text-[1.5em] rounded-md mb-1 text-blue-500 '>Welcome to Merlin</div>
			{props.isLoading && (
				<div className='text-gray-500 w-100 flex flex-col items-center justify-center h-60'>
					<div className='m-0 w-fit'>
						<LoadingIndicator />
					</div>
				</div>
			)}
			{data?.notifications
				.filter((item) => !item.read_status)
				.map((notification) => (
					<NotificationItemMinimal
						key={notification.id}
						id={notification.id}
						merlin_page_url={notification.merlin_page_url}
						category={''}
						subject={notification.subject}
						message={''}
						date={notification.date}
						recipient={notification.recipient}
					/>
				))}

			{!props.isLoading && data?.notifications.filter((item) => !item.read_status).length === 0 && !isFetching && (
				<div className='text-slate-400 text-center pt-16'>No new notifications</div>
			)}
		</div>
	);
}

export function Dashboard() {
	const { selectedFacility, selectedSystem } = useAppSelector((state) => state.userState);
	const { rooms, surgeons } = useFilters();
	const { data } = useGetSystemsQuery({
		healthsystem_id: selectedSystem,
	});
	const isAdmin = data?.user.role === UserRole.admin;

	const facility = data?.facilities?.find((f) => f.id === selectedFacility);
	const showDailyHuddle = facility?.has_scheduled && isAdmin;

	const {
		data: dashboardData,
		error,
		isLoading: loadingDash,
	} = useGetDashboardQuery({
		facility_id: selectedFacility,
	});

	const {
		data: daily_huddle_heatmap,
		isLoading: loading_huddle,
		isFetching: fetching_huddle,
	} = useGetDailyHuddleHeatmapQuery(
		{
			start_date: startDate.toLocaleDateString(),
			end_date: endDate.toLocaleDateString(),
			facility_id: selectedFacility,
			filters: {
				rooms: rooms.all,
				surgeons: surgeons.selected,
			},
		},
		{
			skip: !showDailyHuddle,
		}
	);

	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);
	const [selectedDate, setSelectedDate] = useState(yesterday.toLocaleDateString());

	// Handle api response to create toast message on every error.
	const { createToast } = useToast();
	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: `There was an error connecting to the server.`,
					variant: 'error',
				});
			}
		}
	}, [createToast, error]);

	const isCoreFacility = checkLicenseBits(facility?.license, FacilityLicense.core);
	const isProFacility =
		checkLicenseBits(facility?.license, FacilityLicense.core) ||
		checkLicenseBits(facility?.license, FacilityLicense.block);

	const allHeatmapDates = useMemo(() => {
		return (
			daily_huddle_heatmap?.records.map((elm) => {
				const temp = { ...elm };
				temp.date = adjustDatesForDST(new Date(`${elm.date ?? '1/1/2023'} 00:00:00`));
				return temp;
			}) ?? []
		);
	}, [daily_huddle_heatmap]);

	const [roomFilter, setRoomFilter] = useState<string | null>(null);
	const [surgeonFilter, setSurgeonFilter] = useState<string | null>(null);
	const [elementFilter, setElementFilter] = useState<string | null>(null);

	const currentHeatmapElement = useMemo(() => {
		return allHeatmapDates.filter(
			(d) => new Date(d.date).toLocaleDateString() === new Date(selectedDate).toLocaleDateString()
		)[0];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate, allHeatmapDates]);

	const clickedHeatmapElement = {
		utilization: currentHeatmapElement?.facility_utilization ?? 0,
		case_minutes: currentHeatmapElement?.case_minutes ?? 0,
	};

	const is_future = selectedDate >= today.toLocaleDateString();
	const notifications = [
		`Your facility volume was ${dashboardData?.volume} cases`,
		`Your average room turnover in primetime was ${dashboardData?.turnover} minutes`,
		`Your first case on-time starts rate was ${dashboardData?.fcots_rate}%`,
		`The last day of data received ${toFullDate(dashboardData?.data_upload_max_date ?? new Date())}`,
	];

	// surgeons.isDirty does not seem to work well
	const surgeons_dirty = surgeons.all.length !== surgeons.selected.length;

	return (
		<div className='fixed overflow-hidden'>
			{showDailyHuddle && (
				// <div className='h-screen w-screen fixed top-0 left-0 bg-gradient-to-r from-green-50 to-blue-50 z-[-1]'></div>
				<div className='h-screen w-screen fixed top-0 left-0 bg-white z-[-1]'></div>
			)}
			{showDailyHuddle && (
				<>
					<div className='w-[97vw] flex'>
						<div className='w-[87vw] flex flex-col gap-5 max-h-[78vh] h-[78vh] overflow-y-scroll customscrollbar mr-1.5'>
							<div className='flex justify-between gap-2'>
								<div className='h-60 w-1/2 max-h-60 overflow-y-scroll slate-customscrollbar pr-3 mr-1'>
									<div>
										<NotificationModule
											notifications={notifications}
											isLoading={loadingDash || loading_huddle || fetching_huddle}
										/>
									</div>
								</div>
								<div className='w-1/2 min-w-1/2 flex flex-col justify-between pr-8 mr-3 h-60'>
									<div className='flex justify-between items-center px-8 pl-4 pr-0'>
										<div className='text-slate-400 font-semibold'>Calendar Heatmap</div>
										<div className='w-1/2 flex items-center justify-between z-[300]'>
											<span className=''>
												<Tooltip content='Surgeon Filtering' contentProps={{ side: 'bottom' }}>
													<span className='material-symbols-outlined text-slate-500 mr-2 mt-1'>person</span>
												</Tooltip>
											</span>
											<div className='w-full'>
												<MultiSelect
													hideLabel
													label='Surgeons'
													options={surgeons.all.map(({ id, name }) => ({ value: id, label: name }))}
													onChange={(selections) => {
														const selectedSurgeons = selections.map(({ value: id, label: name }) => ({ id, name }));
														surgeons.update({ selected: selectedSurgeons });
													}}
													value={surgeons.selected.map(({ id, name }) => ({ value: id, label: name }))}
													isWorking={surgeons.isFetching || loading_huddle || fetching_huddle}
												/>
											</div>
										</div>
									</div>
									<div className={`${loading_huddle || fetching_huddle ? 'animate-fastPulse' : ''}`}>
										<>
											<ReactTooltip id='my-tooltip' style={{ backgroundColor: 'rgb(255, 255, 255)', zIndex: 300 }} />
											<CalendarHeatmap
												startDate={startDate}
												endDate={endDate}
												gutterSize={3}
												onClick={(data: any) => {
													if (data.date) {
														setSelectedDate(new Date(data?.date).toLocaleDateString());
													}
												}}
												classForValue={(value: any) => {
													const utilization = (value?.facility_utilization ?? 0).toString();
													let base = 'color-huddle-scale-';

													// if selected date highlight yello
													if (selectedDate === new Date(value?.date).toLocaleDateString()) {
														return 'color-scale-release';
													}

													// if date in future change color
													if (new Date(value?.date).getTime() > yesterday.getTime()) {
														base = 'color-huddle-scale-schedule-';
													}

													if (utilization === '0') {
														return 'color-empty';
													}

													if (utilization.length === 1) {
														return `${base}3`;
													}

													const first_int = utilization.length === 3 ? 9 : utilization.at(0);

													return `${base}${first_int}`;
												}}
												showOutOfRangeDays={true}
												showWeekdayLabels={true}
												values={allHeatmapDates}
												tooltipDataAttrs={(value: {
													date: string;
													count: number;
													case_minutes: number;
													facility_utilization: number;
												}) => {
													return {
														'data-tooltip-id': 'my-tooltip',
														'data-tooltip-html': value.date
															? renderToStaticMarkup(
																	<>
																		<div className='flex flex-col text-black w-fit pl-2'>
																			{!surgeons_dirty && (
																				<>
																					<p className='text-black text-left font-semibold text-p1 pb-0.5'>
																						{value?.facility_utilization ?? 0}%
																					</p>
																					<p
																						className={`text-left font-bold uppercase ${
																							new Date(value?.date).getTime() > yesterday.getTime() ? 'text-purple-500' : 'text-blue-500'
																						} p-0 m-0 text-[0.9em]`}
																					>
																						{new Date(value?.date).getTime() > yesterday.getTime() ? 'Fill Rate' : 'Utilization'}
																					</p>
																				</>
																			)}

																			<p className='text-left text-black text-[0.8em] opacity-50'>
																				On {new Date(value.date).toLocaleDateString()}
																			</p>
																			<div className='flex justify-between mt-3 w-20'>
																				<div>
																					<p className='text-black text-left font-semibold pb-0.5 text-[1em]'>{value.count}</p>
																					<p className='text-left text-black text-[0.7em] opacity-50'>Cases</p>
																				</div>
																				<div className='text-right'>
																					<p className='text-black text-left font-semibold pb-0.5 text-[1em]'>{value.case_minutes}</p>
																					<p className='text-left text-black text-[0.7em] opacity-50'>Minutes</p>
																				</div>
																			</div>
																		</div>
																	</>
															  )
															: null,
													};
												}}
											/>
										</>
									</div>
								</div>
							</div>

							{!is_future && (
								<HuddleYesterday
									date={selectedDate}
									utilization={clickedHeatmapElement.utilization}
									case_minutes={clickedHeatmapElement.case_minutes}
									setRoomFilter={setRoomFilter}
									setSurgeonFilter={setSurgeonFilter}
									setElementFilter={setElementFilter}
									roomFilter={roomFilter}
									surgeonFilter={surgeonFilter}
									elementFilter={elementFilter}
								/>
							)}

							{is_future && (
								<HuddleToday
									date={selectedDate}
									utilization={clickedHeatmapElement.utilization}
									case_minutes={clickedHeatmapElement.case_minutes}
									setRoomFilter={setRoomFilter}
									setSurgeonFilter={setSurgeonFilter}
									setElementFilter={setElementFilter}
									roomFilter={roomFilter}
									surgeonFilter={surgeonFilter}
									elementFilter={elementFilter}
								/>
							)}
						</div>
					</div>
				</>
			)}

			{!showDailyHuddle && (
				<>
					<div className='fixed top-0 left-0 w-screen h-screen bg-blue-50' />
					<div className='z-10 relative'>
						<div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-4'>
							<div className='col-span-2 flex flex-col justify-between'>
								<div>
									<PageHeading>Welcome to Merlin!</PageHeading>
									<p className='text-h5 mb-8'>
										The last day of data received
										<span
											className={classNames('bg-blue-500 text-white mx-1 px-2.5 py-0.5 rounded font-secondary text-p1', {
												'!bg-gray-200 text-black': dashboardData?.is_holiday,
											})}
										>
											{dashboardData?.is_holiday ? (
												<Tooltip
													disableHoverableContent={true}
													content={
														'This is a potential holiday. Check your settings to see if it is included or excluded from other reports in Merlin.'
													}
												>
													{dashboardData && toFullDate(dashboardData?.data_upload_max_date)}
												</Tooltip>
											) : (
												dashboardData && toFullDate(dashboardData?.data_upload_max_date)
											)}
										</span>
										shows...{' '}
									</p>
									<p className='text-h4 mb-2'>
										Your facility volume was <span className='font-bold text-blue-700'>{dashboardData?.volume} cases</span>
									</p>
									<p className='text-h4 mb-2'>
										Your average room turnover in primetime was{' '}
										<span className='font-bold text-blue-700'>{dashboardData?.turnover} minutes</span>
									</p>
									<p className='text-h4 mb-2'>
										Your first case on-time starts rate was{' '}
										<span className='font-bold text-blue-700'>{dashboardData?.fcots_rate}%</span>
									</p>
								</div>
								<div>
									<div className='font-bold text-p1 uppercase flex items-center space-x-3 mb-4 mt-10'>
										<span className='material-symbol'>
											{isCoreFacility || isProFacility ? DashboardLinks.icon.core : DashboardLinks.icon.block}
										</span>
										<span>{isCoreFacility || isProFacility ? 'Core' : 'Block'}</span>
									</div>
									<div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5 gap-6 content-center'>
										{isCoreFacility || isProFacility
											? DashboardLinks.links.core.map((item, i) => (
													<Link
														to={item.path}
														key={i}
														className='h-32 rounded bg-white drop-shadow-md border-white border-2 dark:bg-white-800 dark:hover:border-blue-500 flex item-center px-3'
													>
														<span className='text-p1 text-black font-semibold text-center m-auto'>{item.name}</span>
													</Link>
											  ))
											: DashboardLinks.links.block.map((item, i) => (
													<Link
														to={item.path}
														key={i}
														className='h-32 rounded bg-white drop-shadow-md border-white border-2 dark:bg-white-800 dark:hover:border-blue-500 flex item-center px-3'
													>
														<span className='text-p1 text-black font-semibold text-center m-auto'>{item.name}</span>{' '}
													</Link>
											  ))}
									</div>
								</div>
							</div>
							<div className='col-span-1 md:col-span-2 lg:col-span-1 flex items-end justify-end'>
								{!isProFacility ? (
									<div className='lg:ml-6'>
										<div className='font-bold text-p1 uppercase flex items-center space-x-3 mb-4 mt-10'>
											<span className='material-symbol'>
												{!isCoreFacility ? DashboardLinks.icon.core : DashboardLinks.icon.block}
											</span>
											<span>{!isCoreFacility ? 'Core' : 'Block'}</span>
										</div>
										<div className='bg-blue-700 rounded text-white drop-shadow-md pb-4'>
											<div className='px-6'>
												<p className='font-secondary text-h4 py-6 mb-6'>
													Core & Block are more powerful when they are used together! Save more time, upgrade to Pro!
												</p>
												<p className='text-p2 uppercase tracking-wider font-semibold text-gray-200'>Includes:</p>
												<ul className='space-y-4 text-left py-6 mb-4'>
													{!isCoreFacility
														? DashboardLinks.features.core.map((item, i) => (
																<li className='flex items-center space-x-3' key={i}>
																	<span className='material-symbols-outlined text-blue-400'>task_alt</span>
																	<span className='text-h5'>{item}</span>
																</li>
														  ))
														: DashboardLinks.features.block.map((item, i) => (
																<li className='flex items-center space-x-3' key={i}>
																	<span className='material-symbols-outlined text-blue-400'>task_alt</span>
																	<span className='text-h5'>{item}</span>
																</li>
														  ))}
												</ul>
											</div>

											<a
												href='mailto:MerlinHelp@surgicaldirections.com'
												className='bg-white text-blue font-semibold w-full block py-4 text-center'
											>
												Request a Demo
											</a>
										</div>
									</div>
								) : (
									<>
										<div className='inset-x-0 bottom-0 lg:ml-6 w-full'>
											<div className='font-bold uppercase flex items-center space-x-3 my-4'>
												<span className='material-symbol'>{DashboardLinks.icon.block}</span>
												<span className='text-p1 uppercase'>Block</span>
											</div>
											<div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6 text-white'>
												{DashboardLinks.links.block.map((item, i) => (
													<Link
														to={item.path}
														key={i}
														className='block h-32 rounded bg-white drop-shadow-md border-white border-2 dark:bg-white-800 dark:hover:border-blue-500 flex item-center px-3'
													>
														<span className='text-p1 text-black font-semibold text-center m-auto '>{item.name} </span>
													</Link>
												))}
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
					<Link
						to={Routes.HEATMAP}
						className='bg-white w-full p-3 px-5 my-4 font-bold text-p1 text-black drop-shadow-md uppercase border-2 border-white hover:border-blue-500 hover:text-black flex items-center space-x-3 mb-4'
					>
						<span className='material-symbol'>mode_heat</span>
						<span className='uppercase'>Heatmap</span>
					</Link>
				</>
			)}
		</div>
	);
}

interface HuddleAccordian {
	date: string;
	setRoomFilter: React.Dispatch<React.SetStateAction<string | null>>;
	setSurgeonFilter: React.Dispatch<React.SetStateAction<string | null>>;
	setElementFilter: React.Dispatch<React.SetStateAction<string | null>>;
	roomFilter: string | null;
	surgeonFilter: string | null;
	elementFilter: string | null;
	utilization: number;
	case_minutes: number;
}

function HuddleToday({
	date,
	setRoomFilter,
	setSurgeonFilter,
	roomFilter,
	surgeonFilter,
	utilization,
	case_minutes,
}: HuddleAccordian) {
	const [open, setOpen] = useState(false);
	const { rooms, surgeons } = useFilters();
	const { data, isLoading, isFetching } = useGetScheduledCasesQuery({
		surgery_date: date,
		filters: {
			rooms: rooms.all,
			surgeons: surgeons.selected,
		},
	});

	const { data: schedule_metadata } = useGetScheduleMetadataQuery({
		surgery_date: date,
		filters: {
			rooms: rooms.all,
			surgeons: surgeons.selected,
		},
	});

	const isDateToday = useMemo(() => {
		const today = new Date().toLocaleDateString('en-US');
		const record = new Date(date).toLocaleDateString('en-US');
		return today === record;
	}, [date]);

	// map room name to room index
	const roomIndex = useMemo(() => {
		let i = 0;
		const roomNames = Array.from(new Set(data?.scheduled_cases.map((item) => item.room)));
		return [...roomNames].reduce((acc: { [key: string]: number }, room) => {
			i = i + 1;
			acc[room] = i;
			return acc;
		}, {});
	}, [data?.scheduled_cases]);

	let cases = data?.scheduled_cases ?? [];

	if (roomFilter) {
		cases = cases.filter((item) => item.room === roomFilter);
	}

	if (surgeonFilter) {
		cases = cases.filter((item) => item.surgeon === surgeonFilter);
	}

	cases = cases.map((item) => {
		const temp_item = { ...item, offShade: false };
		temp_item.offShade = roomIndex[item.room] % 2 === 0;
		return temp_item;
	});

	// used to scroll drop down into view
	const ref = useRef<null | HTMLDivElement>(null);
	const handleClick = () => {
		ref.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};
	const grayIds = cases.map((item, i) => (item.offShade ? `${i}` : '99'));

	// open and focus after loading done
	useEffect(() => {
		if (!isLoading && !isFetching) {
			setOpen(true);
		}
	}, [isLoading, isFetching]);

	const surgeons_dirty = surgeons.all.length !== surgeons.selected.length;

	return (
		<div className='mr-1.5 flex flex-col' ref={ref}>
			<div
				className={`${
					open && 'sticky top-0'
				} bg-violet-50 border border-violet-500 rounded-t-md text-violet-500 text-p2 p-4 text-center border-b-violet-100 flex justify-between items-center ${
					!open && 'rounded-b-md border-b-violet-500'
				}`}
			>
				<p className='text-violet-500 font-semibold space-x-2'>
					{isDateToday && 'Today -'} {date}
				</p>
				<div className='flex gap-3'>
					{/* Filters for day */}
					{roomFilter && (
						<div
							onClick={() => {
								setRoomFilter(null);
							}}
							className='z-10 flex text-p3 px-2 py-1 items-center rounded-lg text-center cursor-pointer border border-purple-500 bg-purple-50 text-purple-500 whitespace-nowrap'
						>
							<div className='mr-1'>X | </div>
							<div>{roomFilter}</div>
						</div>
					)}
					{surgeonFilter && (
						<div
							onClick={() => {
								setSurgeonFilter(null);
							}}
							className='z-10 flex text-p3 px-2 py-1 items-center rounded-lg text-center cursor-pointer border border-sky-500 bg-sky-50 text-sky-500 whitespace-nowrap'
						>
							<div className='mr-1'>X | </div>
							<div>{surgeonFilter}</div>
						</div>
					)}
					{!surgeons_dirty && (
						<Tooltip content='Utilization'>
							<div className='flex w-10 text-gray-500 items-center'>
								<div className='mb-0.5 mr-0.5'>
									{isLoading || isFetching ? (
										<div className='text-gray-500 flex justify-center'>
											<LoadingIndicator />
										</div>
									) : (
										<p className='font-semibold text-[1em]'>{utilization}</p>
									)}
								</div>
								<div className='mr-1'>
									<span className='material-symbols-outlined text-[1.2em]'>percent</span>
								</div>
							</div>
						</Tooltip>
					)}

					<Tooltip content='Case Minutes'>
						<div className='flex w-fit text-gray-500 items-center'>
							<div className='mb-0.5 mr-1'>
								{isLoading || isFetching ? (
									<div className='text-gray-500 flex justify-center'>
										<LoadingIndicator />
									</div>
								) : (
									<p className='font-semibold text-[1em]'>{numberWithCommas(case_minutes.toString())}</p>
								)}
							</div>
							<div className='mr-1'>
								<span className='material-symbols-outlined text-[1.2em]'>pace</span>
							</div>
						</div>
					</Tooltip>

					<Tooltip content='Records'>
						<div className='flex w-16 text-gray-500 items-center'>
							<div className='mb-0.5 mr-1'>
								{isLoading || isFetching ? (
									<div className='text-gray-500 flex justify-center'>
										<LoadingIndicator />
									</div>
								) : (
									<p className='font-semibold text-[1em]'>{cases.length}</p>
								)}
							</div>
							<div className='mr-1'>
								<span className='material-symbols-outlined text-[1.2em]'>table_rows</span>
							</div>
						</div>
					</Tooltip>
					<Button
						onClick={() => {
							setOpen(!open);
							handleClick();
						}}
						sizeX='square'
						variant={open ? 'primary' : 'primary-ghost'}
						className={`border border-violet-500 rounded-full ${open && 'rotate-180 bg-violet-500'}`}
					>
						<span className={`material-symbols-outlined ${open ? 'text-white' : 'text-violet-500'}`}>arrow_drop_down</span>
					</Button>
				</div>
			</div>
			<div
				className={`border border-r-violet-500 border-l-violet-500 mb-2 ${
					open ? 'h-fit' : 'hidden'
				} transition-all duration-75`}
			>
				<DataTable
					minimalStyle
					disableRowCounter
					disablePagination
					highlightedRows={grayIds}
					columns={[
						{
							header: 'Status',
							accessorKey: 'status',
							cell: ({ row }) => {
								return (
									<MetaDataModule
										id={row.original.id}
										openState={open}
										room={row.original.room}
										surgeon={row.original.surgeon}
										start={row.original.start}
										schedule_metadata={schedule_metadata?.records ?? {}}
										schedule_key={`${date}:${row.original.room_id}:${row.original.surgeon_id}:${row.original.case_order}:${row.original.procedure_description}`}
										end={row.original.end}
										surgeon_id={row.original.surgeon_id}
										procedure={row.original.procedure_description}
										room_id={row.original.room_id}
										date={date}
										case_order={row.original.case_order}
									/>
								);
							},
							enableSorting: false,
						},
						{
							header: 'Room',
							accessorKey: 'room',
							enableSorting: false,
							cell: ({ row }) => {
								return (
									<div
										className={classNames(
											'bg-gray-50 text-p3 px-2 py-1 rounded-lg text-center w-fit cursor-pointer border border-transparent',
											roomIndex[row.original.room] % 2 === 0 && `bg-indigo-50  text-indigo-500 hover:border-indigo-500`,
											roomIndex[row.original.room] % 2 !== 0 && ` bg-purple-50  text-purple-500  hover:border-purple-500`
										)}
										onClick={() => {
											setRoomFilter(row.original.room);
											handleClick();
										}}
									>
										{truncateLabel(row.original.room, 50)}
									</div>
								);
							},
						},
						{
							header: 'Start',
							accessorKey: 'start',
							enableSorting: false,
						},
						{
							header: 'End',
							accessorKey: 'end',
							enableSorting: false,
						},
						{
							header: 'Surgeon',
							accessorKey: 'surgeon',
							enableSorting: false,
							cell: ({ row }) => {
								return (
									<div
										onClick={() => {
											setSurgeonFilter(row.original.surgeon);
											handleClick();
										}}
										className='text-p3 px-2 py-1 bg-sky-50 rounded-lg text-sky-500 text-center w-fit cursor-pointer border border-transparent hover:border-sky-500'
									>
										{truncateLabel(row.original.surgeon, 50)}
									</div>
								);
							},
						},
						{
							header: 'Procedure',
							accessorKey: 'procedure_description',
							cell: ({ row }) => {
								return (
									<Tooltip content={row.original.procedure_description}>
										<div className='text-p3 px-2 py-1 bg-gray-50 rounded-lg text-gray-500 text-center w-fit'>
											{truncateLabel(row.original.procedure_description, 50)}
										</div>
									</Tooltip>
								);
							},
							enableSorting: false,
						},
					]}
					data={cases}
					title={''}
				/>
			</div>
		</div>
	);
}

function MetaDataModule({
	date,
	room,
	surgeon,
	start,
	end,
	procedure,
	schedule_metadata,
	schedule_key,
	surgeon_id,
	case_order,
	room_id,
}: MetaDataModuleProps) {
	const { data } = useSystem();
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const elements_template = useMemo(() => {
		const facility = data?.facilities?.find((f) => f.id === selectedFacility);
		return facility?.case_elements ?? defaultCaseElementsTemplate;
	}, [data?.facilities, selectedFacility]);

	const saved_case_elements = useMemo(() => {
		return schedule_metadata[schedule_key]?.metadata ?? defaultCaseElements;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [schedule_metadata]);

	const required_missing_elements_message = useMemo(() => {
		const required = elements_template?.required.map((e) => e.replace(' ', '_'));
		const required_selected = saved_case_elements.checked.filter((e) => required.includes(e));
		const diff = required.filter((e) => !required_selected.includes(e));

		if (diff.length > 0) {
			return `${diff[0].replace('_', ' ')}${Math.max(0, diff.length - 1) !== 0 ? ` +${diff.length - 1}...` : ''}`;
		} else {
			return 'Ready';
		}
	}, [elements_template?.required, saved_case_elements.checked]);

	const ratio_complete = useMemo(() => {
		const required = elements_template?.required.map((e) => e.replace(' ', '_'));
		const required_selected = saved_case_elements.checked.filter((e) => required.includes(e));
		const ratio = Math.round((required_selected.length / required.length) * 10);

		return ratio;
	}, [elements_template?.required, saved_case_elements.checked]);

	return (
		<ScheduleRecordDialog
			date={date}
			room={room}
			surgeon={surgeon}
			procedure={procedure}
			start={start}
			surgeon_id={surgeon_id}
			case_order={case_order}
			room_id={room_id}
			saved_case_elements={saved_case_elements}
			end={end}
			elements_template={elements_template}
		>
			<div
				className={classNames(
					'flex text-p3 px-2 py-1 rounded-lg whitespace-nowrap  text-center w-fit cursor-pointer border border-transparent',
					ratio_complete <= 3 && 'bg-rose-50 text-rose-500 hover:border-rose-500',
					ratio_complete > 3 && ratio_complete <= 7 && 'bg-orange-50 text-orange-500 hover:border-orange-500',
					ratio_complete > 7 && ratio_complete < 10 && 'bg-amber-50 text-amber-500 hover:border-amber-500',
					ratio_complete === 10 && 'bg-green-50 text-green-500 hover:border-green-500'
				)}
			>
				<div className='mr-1'>{required_missing_elements_message}</div>
				<span className='material-symbols-outlined text-[1.4em]'>edit</span>
			</div>
		</ScheduleRecordDialog>
	);
}

function HistoricalScheduleMetaDataModule({
	id,
	start,
	procedure,
	setIntraopSelectState,
	currentIntraopSelectState,
	setSelectedScheduleRecord,
	selectedIntraopRecords,
	setSelectedIntraopRecords,
	intraop_schedule_map,
	selectedScheduleRecord,
	schedule_key,
	surgeon_id,
}: HistoricalMetaDataModuleProps) {
	const isThisRecordSelected = selectedScheduleRecord?.id === id;
	const isAnotherRecordSelected = selectedScheduleRecord?.id !== id && selectedScheduleRecord?.id !== 0;
	const wasThisLastRecordSelected = selectedScheduleRecord?.last_selected_schedule_key === schedule_key;

	const ref = useRef<null | HTMLDivElement>(null);
	const handleClick = () => {
		ref.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	};

	// used to scroll back to this record after linking
	useEffect(() => {
		if (wasThisLastRecordSelected) {
			handleClick();
		}
	}, [wasThisLastRecordSelected]);

	const is_linked = intraop_schedule_map[schedule_key]?.link_key ?? false;

	return (
		<div
			ref={ref}
			className={classNames(
				'flex text-p3 px-2 py-1 rounded-lg whitespace-nowrap  text-center w-fit cursor-pointer border border-transparent',
				isThisRecordSelected && 'bg-blue-500 text-white hover:bg-blue-600 rounded-sm text-center justify-center w-32',
				isAnotherRecordSelected && 'bg-slate-100 text-slate-500',
				!isThisRecordSelected && !isAnotherRecordSelected && !is_linked && 'bg-rose-50 text-rose-500 hover:border-rose-500',
				is_linked && !isThisRecordSelected && 'bg-emerald-50 text-emerald-500 hover:border-emerald-500'
			)}
			onClick={() => {
				if (!isThisRecordSelected) {
					// eslint-disable-next-line no-extra-boolean-cast
					setIntraopSelectState(!!!currentIntraopSelectState);
					setSelectedScheduleRecord({
						id: id,
						start: start,
						surgeon_id: surgeon_id,
						procedure_description: procedure,
						schedule_key: schedule_key,
						last_selected_schedule_key: '',
					});
				} else {
					// reset selectedScheduleRecord
					// eslint-disable-next-line no-extra-boolean-cast
					setIntraopSelectState(!!!currentIntraopSelectState);
					setSelectedScheduleRecord({
						id: 0,
						start: '',
						surgeon_id: 0,
						procedure_description: '',
						schedule_key: '',
						last_selected_schedule_key: schedule_key,
					});
				}
			}}
		>
			{!isThisRecordSelected && !isAnotherRecordSelected && (
				<div className='mr-1'>{is_linked ? is_linked : 'No Link'}</div>
			)}
			{isThisRecordSelected && selectedIntraopRecords.length === 0 && <div className='mr-1'>X | Select Record...</div>}
			{isThisRecordSelected && selectedIntraopRecords.length !== 0 && (
				<div
					className='mr-1'
					onClick={() => {
						setSelectedIntraopRecords([]);
					}}
				>
					Confirm
				</div>
			)}
			{isAnotherRecordSelected && <div className='mr-1'>---</div>}
			{selectedScheduleRecord?.id !== id && !isAnotherRecordSelected && (
				<span className='material-symbols-outlined text-[1.4em]'>edit</span>
			)}
		</div>
	);
}
// fix loading issue
function HistoricalIntraopMetaDataModule({
	id,
	date = '',
	procedure,
	currentIntraopSelectState,
	setSelectedIntraopRecords,
	match_score,
	intraop_schedule_map,
	case_order = 0,
	selectedIntraopRecords,
	room_id = 0,
	selectedScheduleRecord,
	surgeon_id,
}: HistoricalMetaDataModuleProps) {
	const [addIntraopLink] = useAddIntraopScheduleParentLinkMutation();
	const [removeIntraopLink] = useRemoveIntraopScheduleParentLinkMutation();
	const linked_short_key = useMemo(() => {
		// all keys in intraop_schedule_map
		const keys = Object.keys(intraop_schedule_map);

		// find value that matches room_id and surgeon_id and case_order and procedure_description
		const found_key = keys.filter((key) => {
			const value = intraop_schedule_map[key];
			return value.room_id === room_id && value.surgeon_id === surgeon_id && value.case_order === case_order;
		});

		let link_key: string | boolean = false;

		if (found_key.length > 0) {
			link_key = intraop_schedule_map[found_key[0]].link_key;
		}

		return link_key;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [intraop_schedule_map]);

	const linked_long_key = useMemo(() => {
		// all keys in intraop_schedule_map
		const keys = Object.keys(intraop_schedule_map);

		// find value that matches room_id and surgeon_id and case_order and procedure_description
		const found_key = keys.filter((key) => {
			const value = intraop_schedule_map[key];
			return value.room_id === room_id && value.surgeon_id === surgeon_id && value.case_order === case_order;
		});

		let link_key: string | boolean = false;

		if (found_key.length > 0) {
			link_key = found_key[0];
		}

		return link_key;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [intraop_schedule_map]);

	const isMappedToAnotherScheduleRecord = useMemo(() => {
		return linked_short_key ? linked_long_key !== selectedScheduleRecord.schedule_key : false;
	}, [linked_short_key, linked_long_key, selectedScheduleRecord.schedule_key]);

	const match_score_number = clamp(match_score ?? 0, 0, 100);

	return (
		<div className='z-[-1em]'>
			{currentIntraopSelectState ? (
				<div className='flex items-center'>
					<Checkbox
						checked={selectedIntraopRecords.filter((e) => e === id).length > 0 || linked_short_key ? true : false}
						onChange={(event) => {
							// if switching to, then wipe previous data
							if (event.target.checked) {
								// if not already selected, then add to selected
								if (selectedIntraopRecords.filter((e) => e === id).length === 0) {
									setSelectedIntraopRecords([...selectedIntraopRecords, id]);
									addIntraopLink({
										surgery_date: convertDateFormat(date),
										room_id: room_id,
										surgeon_id: surgeon_id,
										case_order: case_order,
										metadata: 'undefined',
										procedure_description: procedure,
										schedule_key: selectedScheduleRecord.schedule_key ?? '',
									});
								}
							} else {
								// remove from selected
								setSelectedIntraopRecords(selectedIntraopRecords.filter((e) => e !== id));
								removeIntraopLink({
									surgery_date: convertDateFormat(date),
									room_id: room_id,
									surgeon_id: surgeon_id,
									case_order: case_order,
									metadata: 'undefined',
									procedure_description: procedure,
									schedule_key: selectedScheduleRecord.schedule_key ?? '',
								});
							}
						}}
						label={''}
						disabled={isMappedToAnotherScheduleRecord}
					/>
					<div
						className={classNames(
							'text-p3 ml-2 px-2 py-1 rounded-md',
							currentIntraopSelectState && match_score_number <= 20 && `bg-rose-50  text-rose-500`,
							currentIntraopSelectState &&
								match_score_number > 20 &&
								match_score_number <= 60 &&
								`bg-orange-50  text-orange-500`,
							currentIntraopSelectState && match_score_number > 60 && `bg-green-50  text-green-500`
						)}
					>
						{match_score_number}% Match
					</div>
				</div>
			) : (
				<div
					className={classNames(
						'flex text-p3 px-2 py-1 rounded-lg whitespace-nowrap  text-center w-fit cursor-pointer border border-transparent items-center',
						!linked_short_key && 'bg-slate-100 text-slate-500',
						linked_short_key && 'bg-emerald-50 text-emerald-500'
						// row.original.record_type === 'schedule' && ` bg-orange-50  text-orange-500  hover:border-orange-500`
					)}
				>
					<div className='mr-1'>{linked_short_key ? linked_short_key : 'Unlinked'}</div>
					{linked_short_key && (
						<span className='material-symbols-outlined text-[1.4em] rotate-45 text-emerald-500'>link</span>
					)}
				</div>
			)}
		</div>
	);
}

function IntraopNoteModule({
	id,
	procedure,
	date,
	room,
	surgeon,
	start,
	end,
	scheduledCases,
	surgeon_id,
	intraop_note_map,
	case_order,
	intraop_schedule_map,
	room_id,
}: {
	procedure: string;
	id: number;
	date: string;
	room: string;
	surgeon: string;
	start: string;
	end: string;
	intraop_note_map: IntraopNoteKeyMap;
	intraop_schedule_map: ScheduleKeyMapItem;
	surgeon_id: number;
	case_order: number;
	room_id: number;
	scheduledCases: ScheduledCaseItem[];
}) {
	const delay_reason = intraop_note_map[id]?.delay_reason ?? 'No Notes';
	const long_schedule_key = useMemo(() => {
		// all keys in intraop_schedule_map
		const keys = Object.keys(intraop_schedule_map);

		// find value that matches room_id and surgeon_id and case_order and procedure_description
		const found_key = keys.filter((key) => {
			const value = intraop_schedule_map[key];
			return value.room_id === room_id && value.surgeon_id === surgeon_id && value.case_order === case_order;
		});

		let link_key = '';

		if (found_key.length > 0) {
			link_key = found_key[0];
		}

		return link_key;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [intraop_schedule_map]);
	return (
		<IntraopRecordDialog
			surgeon={surgeon}
			surgeon_id={surgeon_id}
			room_id={room_id}
			long_schedule_key={long_schedule_key}
			case_order={case_order}
			room={room}
			procedure={procedure}
			date={date}
			id={id}
			start={start}
			end={end}
			scheduledCases={scheduledCases}
			delay_reason={delay_reason ?? 'No notes'}
		>
			<div className='cursor-pointer flex items-center justify-between text-p3 px-2 py-1 bg-slate-100 rounded-lg text-slate-500 text-center w-fit border border-transparent hover:border-gray-500'>
				<span className='material-symbols-outlined text-[1.2em] mr-1'>edit</span>
				<div>{truncateLabel(delay_reason, 25)}</div>
			</div>
		</IntraopRecordDialog>
	);
}

function HuddleYesterday({
	date,
	setRoomFilter,
	setSurgeonFilter,
	roomFilter,
	surgeonFilter,
	elementFilter,
	setElementFilter,
	case_minutes,
	utilization,
}: HuddleAccordian) {
	const [open, setOpen] = useState(false);
	const [intraopSelectable, setIntraopSelectable] = useState(false);
	const [selectedIntraopRecords, setSelectedIntraopRecords] = useState<number[]>([]);
	const [selectedScheduleRecord, setSelectedScheduleRecord] = useState<ScheduleRecord>({
		id: 0,
		procedure_description: '',
		surgeon_id: 0,
		start: '0:00',
		schedule_key: '',
	});
	const { rooms, surgeons } = useFilters();
	const { data, isLoading, isFetching } = useGetIntraopScheduledCasesMeshedQuery(
		{
			surgery_date: date,
			filters: {
				rooms: rooms.all,
				surgeons: surgeons.selected,
			},
		},
		{
			skip: false,
		}
	);

	const { data: schedule_object } = useGetIntraopScheduleMapQuery(
		{
			surgery_date: date,
			filters: {
				rooms: rooms.all,
				surgeons: surgeons.selected,
			},
		},
		{
			skip: false,
		}
	);

	const { data: intraop_object } = useGetIntraopNoteMapQuery(
		{
			surgery_date: date,
			filters: {
				rooms: rooms.all,
				surgeons: surgeons.selected,
			},
		},
		{
			skip: false,
		}
	);

	const calculateMatchScore = (
		intraop_start: string,
		intraop_surgeon_id: number,
		schedule_procedure: string,
		intraop_procedure: string
	) => {
		const actual_start_minute = parseInt(intraop_start.split(':')[0]) * 60;
		const scheduled_start_minute = parseInt(selectedScheduleRecord.start.split(':')[0]) * 60;
		const scheduled_start_time = scheduled_start_minute + parseInt(selectedScheduleRecord.start.split(':')[1]);
		const actual_start_time = actual_start_minute + parseInt(intraop_start.split(':')[1]);
		const is_same_surgeon = intraop_surgeon_id === selectedScheduleRecord.surgeon_id ? 1 : 0;

		// bag of characters matching between schedule and intraop procedure
		const matching_characters = schedule_procedure.split('').filter((e) => intraop_procedure.includes(e)).length;

		const match_procedure_score = matching_characters / Math.max(schedule_procedure.length, intraop_procedure.length);

		// convert difference to percentage based score
		const difference = Math.abs(actual_start_time - scheduled_start_time) / 1440;

		return Math.round((is_same_surgeon * 0.5 + match_procedure_score * 0.3 - difference * 0.2) * 1000) / 10;
	};

	let cases = data?.meshed_records ?? [];

	const scheduledCases = useMemo(() => {
		return cases
			.filter((item) => item.record_type === 'schedule')
			.map((item) => {
				const temp_obj = { ...item };
				temp_obj.schedule_key = `${convertDateFormat(date)}:${item.room_id}:${item.surgeon_id}:${item.case_order}:${
					item.procedure_description
				}`;
				return temp_obj;
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.meshed_records]);

	if (roomFilter) {
		cases = cases.filter((item) => item.room === roomFilter || selectedScheduleRecord.id === item.id);
	}

	if (surgeonFilter) {
		cases = cases.filter((item) => item.surgeon === surgeonFilter || selectedScheduleRecord.id === item.id);
	}

	if (elementFilter) {
		cases = cases.filter((item) => item.record_type === elementFilter);
	}

	// used to scroll drop down into view
	const ref = useRef<null | HTMLDivElement>(null);
	const handleClick = () => {
		ref.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	// hide schedule elements & attach match score to each case
	if (intraopSelectable) {
		cases = cases.filter((item) => selectedScheduleRecord.id === item.id || item.record_type === 'intraop');
		cases = cases.map((item) => ({
			...item,
			match_score: calculateMatchScore(
				item.start,
				item.surgeon_id,
				selectedScheduleRecord.procedure_description,
				item.procedure_description
			),
		}));

		// sort from greatest to smallest match score
		cases = cases.sort((a, b) => b.match_score - a.match_score);

		// place record_type schedule elements at top
		cases = [
			...cases.filter((item) => item.record_type === 'schedule'),
			...cases.filter((item) => item.record_type === 'intraop'),
		];

		handleClick();
	}

	// open and focus after loading done
	useEffect(() => {
		if (!isLoading && !isFetching) {
			setOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isFetching]);

	const grayIds = cases.map((item, i) => (item.record_type === 'schedule' ? `${i}` : '99'));

	const intraop_schedule_map: ScheduleKeyMapItem = schedule_object?.records ?? {};
	const intraop_note_map: IntraopNoteKeyMap = intraop_object?.records ?? {};

	const surgeons_dirty = surgeons.all.length !== surgeons.selected.length;

	return (
		<div className='mr-1.5 flex flex-col' ref={ref}>
			<div
				className={`${
					open && 'sticky top-0 z-50'
				} bg-blue-50 border border-blue-500 rounded-t-md text-blue-500 text-p2 p-4 text-center border-b-blue-100 flex justify-between items-center ${
					!open && 'rounded-b-md border-b-gray-500'
				}`}
			>
				<p className='text-blue-500 font-semibold space-x-2'>
					{date}
					{/* {selectedScheduleRecord.id !== 0 && (
						<div className='flex'>
							<div>{selectedScheduleRecord.}</div>
						</div>
					)} */}
				</p>
				<div className='flex gap-3'>
					{/* Filters for day */}
					{roomFilter && (
						<div
							onClick={() => {
								setRoomFilter(null);
							}}
							className='z-10 flex text-p3 px-2 py-1 items-center rounded-lg text-center cursor-pointer border border-purple-500 bg-purple-50 text-purple-500 whitespace-nowrap'
						>
							<div className='mr-1'>X | </div>
							<div>{roomFilter}</div>
						</div>
					)}
					{surgeonFilter && (
						<div
							onClick={() => {
								setSurgeonFilter(null);
							}}
							className='z-10 flex text-p3 px-2 py-1 items-center rounded-lg text-center cursor-pointer border border-sky-500 bg-sky-50 text-sky-500 whitespace-nowrap'
						>
							<div className='mr-1'>X | </div>
							<div>{surgeonFilter}</div>
						</div>
					)}

					{elementFilter && (
						<div
							onClick={() => {
								setElementFilter(null);
							}}
							className='z-10 flex text-p3 px-3 py-1 items-center rounded-lg text-center cursor-pointer border border-orange-500 bg-orange-50 text-orange-500 whitespace-nowrap'
						>
							<div className='mr-1'>X | </div>
							<div>{elementFilter === 'schedule' ? 'Schedule' : 'Actual'}</div>
						</div>
					)}

					{!surgeons_dirty && (
						<Tooltip content='Utilization'>
							<div className='flex w-10 text-gray-500 items-center'>
								<div className='mb-0.5 mr-0.5'>
									{isLoading || isFetching ? (
										<div className='text-gray-500 flex justify-center'>
											<LoadingIndicator />
										</div>
									) : (
										<p className='font-semibold text-[1em]'>{utilization}</p>
									)}
								</div>
								<div className='mr-1'>
									<span className='material-symbols-outlined text-[1.2em]'>percent</span>
								</div>
							</div>
						</Tooltip>
					)}

					<Tooltip content='Case Minutes'>
						<div className='flex w-fit text-gray-500 items-center'>
							<div className='mb-0.5 mr-1'>
								{isLoading || isFetching ? (
									<div className='text-gray-500 flex justify-center'>
										<LoadingIndicator />
									</div>
								) : (
									<p className='font-semibold text-[1em]'>{numberWithCommas(case_minutes.toString())}</p>
								)}
							</div>
							<div className='mr-1'>
								<span className='material-symbols-outlined text-[1.2em]'>pace</span>
							</div>
						</div>
					</Tooltip>

					<Tooltip content='Records'>
						<div className='flex w-16 text-gray-500 items-center'>
							<div className='mb-0.5 mr-1'>
								{isLoading || isFetching ? (
									<div className='text-gray-500 flex justify-center'>
										<LoadingIndicator />
									</div>
								) : (
									<p className='font-semibold text-[1em]'>{cases.length}</p>
								)}
							</div>
							<div className='mr-1'>
								<span className='material-symbols-outlined text-[1.2em]'>table_rows</span>
							</div>
						</div>
					</Tooltip>
					<Button
						onClick={() => {
							setOpen(!open);
							handleClick();
						}}
						sizeX='square'
						variant={open ? 'primary' : 'primary-ghost'}
						className={`rounded-full ${open && 'rotate-180'}`}
					>
						<span className='material-symbols-outlined'>arrow_drop_down</span>
					</Button>
				</div>
			</div>
			<div
				className={`border border-r-blue-500 border-l-blue-500 mb-2 ${
					open ? 'h-fit' : 'hidden'
				} transition-all duration-75`}
			>
				<DataTable
					minimalStyle
					disableRowCounter
					disablePagination
					highlightedRows={grayIds}
					columns={[
						{
							header: 'Link',
							accessorKey: 'status',
							cell: ({ row }) => {
								if (row.original.record_type === 'schedule') {
									return (
										<HistoricalScheduleMetaDataModule
											id={row.original.id}
											openState={open}
											start={row.original.start}
											intraop_schedule_map={intraop_schedule_map}
											setSelectedIntraopRecords={setSelectedIntraopRecords}
											selectedIntraopRecords={selectedIntraopRecords}
											setIntraopSelectState={setIntraopSelectable}
											currentIntraopSelectState={intraopSelectable}
											selectedScheduleRecord={selectedScheduleRecord}
											setSelectedScheduleRecord={setSelectedScheduleRecord}
											schedule_key={`${convertDateFormat(date)}:${row.original.room_id}:${row.original.surgeon_id}:${
												row.original.case_order
											}:${row.original.procedure_description}`}
											surgeon_id={row.original.surgeon_id}
											procedure={row.original.procedure_description}
										/>
									);
								} else {
									return (
										<HistoricalIntraopMetaDataModule
											id={row.original.id}
											openState={open}
											start={row.original.start}
											intraop_schedule_map={intraop_schedule_map}
											selectedIntraopRecords={selectedIntraopRecords}
											setIntraopSelectState={setIntraopSelectable}
											currentIntraopSelectState={intraopSelectable}
											selectedScheduleRecord={selectedScheduleRecord}
											schedule_key={`${convertDateFormat(date)}:${row.original.room_id}:${row.original.surgeon_id}:${
												row.original.case_order
											}:${row.original.procedure_description}`}
											match_score={row.original.match_score ?? 0}
											setSelectedIntraopRecords={setSelectedIntraopRecords}
											setSelectedScheduleRecord={setSelectedScheduleRecord}
											surgeon_id={row.original.surgeon_id}
											room_id={row.original.room_id}
											date={date}
											case_order={row.original.case_order}
											procedure={row.original.procedure_description}
										/>
									);
								}
							},
							enableSorting: false,
						},
						{
							header: 'Type',
							accessorKey: 'record_type',
							cell: ({ row }) => {
								return (
									<div
										className={classNames(
											'bg-gray-50 text-p3 px-2 py-1 rounded-lg text-center w-fit cursor-pointer border border-transparent',
											row.original.record_type === 'intraop' && `bg-slate-100  text-slate-500 hover:border-slate-500`,
											row.original.record_type === 'schedule' && ` bg-orange-50  text-orange-500  hover:border-orange-500`
										)}
										onClick={() => {
											if (row.original.record_type === 'intraop') {
												setElementFilter('intraop');
											} else {
												setElementFilter('schedule');
											}
											handleClick();
										}}
									>
										{row.original.record_type === 'intraop' ? 'Actual' : 'Scheduled'}
									</div>
								);
							},
							enableSorting: false,
						},
						{
							header: 'Room',
							accessorKey: 'room',
							enableSorting: false,
							cell: ({ row }) => {
								return (
									<div
										className={classNames(
											'whitespace-nowrap text-p3 px-2 py-1 rounded-lg text-center w-fit cursor-pointer border border-transparent bg-indigo-50  text-indigo-500 hover:border-indigo-500'
										)}
										onClick={() => {
											setRoomFilter(row.original.room);
											handleClick();
										}}
									>
										{truncateLabel(row.original.room, 50)}
									</div>
								);
							},
						},
						{
							header: 'Start',
							accessorKey: 'start',
							enableSorting: false,
						},
						{
							header: 'End',
							accessorKey: 'end',
							enableSorting: false,
						},
						{
							header: 'Surgeon',
							accessorKey: 'surgeon',
							enableSorting: false,
							cell: ({ row }) => {
								return (
									<div
										onClick={() => {
											setSurgeonFilter(row.original.surgeon);
											handleClick();
										}}
										className='whitespace-nowrap text-p3 px-2 py-1 bg-sky-50 rounded-lg text-sky-500 text-center w-fit cursor-pointer border border-transparent hover:border-sky-500'
									>
										{truncateLabel(row.original.surgeon, 19)}
									</div>
								);
							},
						},
						{
							header: 'Notes',
							accessorKey: 'procedure_description',
							cell: ({ row }) => {
								if (row.original.record_type === 'intraop') {
									return (
										<IntraopNoteModule
											surgeon={row.original.surgeon}
											room={row.original.room}
											date={date}
											intraop_schedule_map={intraop_schedule_map}
											room_id={row.original.room_id}
											case_order={row.original.case_order}
											surgeon_id={row.original.surgeon_id}
											intraop_note_map={intraop_note_map}
											start={row.original.start}
											end={row.original.end}
											scheduledCases={scheduledCases}
											procedure={row.original.procedure_description}
											id={row.original.id}
										/>
									);
								} else {
									return null;
								}
							},
							enableSorting: false,
						},
						{
							header: 'Procedure',
							accessorKey: 'procedure_description',
							cell: ({ row }) => {
								return (
									<Tooltip content={row.original.procedure_description}>
										<div className='whitespace-nowrap text-p3 px-2 py-1 bg-gray-50 rounded-lg text-gray-500 text-center w-fit'>
											{truncateLabel(row.original.procedure_description, 23)}
										</div>
									</Tooltip>
								);
							},
							enableSorting: false,
						},
					]}
					data={cases}
					title={''}
				/>
			</div>
		</div>
	);
}

function IntraopRecordDialog({
	children,
	date,
	room,
	surgeon,
	id,
	procedure,
	start,
	end,
	scheduledCases,
	delay_reason,
	long_schedule_key,
}: {
	children: React.ReactNode;
	date: string;
	id: number;
	room: string;
	surgeon: string;
	surgeon_id: number;
	case_order: number;
	room_id: number;
	start: string;
	end: string;
	procedure: string;
	long_schedule_key: string;
	delay_reason: string;
	scheduledCases: ScheduledCaseItem[];
}) {
	const [openViewModal, setOpenViewModal] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [delay_reason_state, setDelayReasonState] = useState<string>(delay_reason);

	useEffect(() => {
		setDelayReasonState(delay_reason);
	}, [delay_reason]);

	const [setIntraopNote] = useSetIntraopNotesMutation();

	const submitNote = async () => {
		setSubmitted(true);
		const response = await setIntraopNote({
			intraop_id: id,
			delay_reason: delay_reason_state,
		});

		handleApiResponse(response, {
			success: (payload) => {
				setSubmitted(false);
				setIsDirty(false);
				setTimeout(() => {
					setOpenViewModal(false);
				}, 0);
			},
			error: (payload) => {
				console.log(payload);
			},
		});
	};

	const schedule_record = scheduledCases.filter((record) => record.schedule_key === long_schedule_key)[0] ?? {
		room: '',
		surgeon: '',
		start: '',
		end: '',
		procedure_description: '',
	};

	return (
		<Dialog
			open={openViewModal}
			actionButtons={[
				{
					onClick: () => {
						submitNote();
					},
					children: 'Confirm',
					isWorking: submitted,
					disabled: !isDirty,
				},
				{
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onClick: () => {
						setOpenViewModal(false);
					},
					children: 'Exit',
					disabled: submitted,
				},
			]}
			sizeX='sm'
			title={
				<div className='flex'>
					<p className='text-p1 font-semibold'>Case Metadata</p>
				</div>
			}
			minimalHeaderStyle
			trigger={children}
			// open={dialogOpen}
			onOpenChange={async (isBeingOpened) => {
				if (isBeingOpened) {
					setOpenViewModal(true);
				} else {
					setOpenViewModal(false);
				}
			}}
		>
			<div className={'h-96 overflow-auto'}>
				<div className='flex flex-col px-4'>
					<div className='border border-orange-500 h-fit flex flex-col rounded-md p-3 bg-orange-50 pt-2'>
						<p className={'px-4 pl-1 text-[0.7em] text-gray-700 tracking-wider uppercase font-secondary mb-2'}>Scheduled</p>
						{schedule_record.room !== '' ? (
							<>
								<div className='flex gap-2'>
									<div className='flex flex-col'>
										<p className='text-p3 px-2 py-1 bg-orange-300 text-white rounded-lg text-center w-fit whitespace-nowrap'>
											{date}
										</p>
									</div>
									<div className='flex flex-col'>
										<p className='text-p3 px-2 py-1 bg-orange-300 rounded-lg text-white text-center w-fit whitespace-nowrap'>
											{truncateLabel(schedule_record.room, 11)}
										</p>
									</div>
									<div className='flex flex-col'>
										<p className='text-p3 px-2 py-1 bg-orange-300 rounded-lg text-white text-center w-fit whitespace-nowrap'>
											{schedule_record.start} - {schedule_record.end}
										</p>
									</div>
								</div>
								<div className='flex gap-2 mt-4'>
									<div className='flex flex-col'>
										<p className='text-p3 px-2 py-1 bg-orange-300 rounded-lg text-white text-center w-fit whitespace-nowrap'>
											{truncateLabel(schedule_record.surgeon, 17)}
										</p>
									</div>
									<Tooltip content={schedule_record.procedure_description}>
										<div className='flex flex-col'>
											<p className='text-p3 px-2 py-1 bg-orange-300 rounded-lg text-white text-center w-fit whitespace-nowrap'>
												{truncateLabel(schedule_record.procedure_description, 20)}
											</p>
										</div>
									</Tooltip>
								</div>
							</>
						) : (
							<div className='h-16 text-center flex justify-center items-center'>
								<p className='text-slate-500 text-p3 mr-3 mb-3'>No Schedule Data</p>
							</div>
						)}
					</div>
					<div className='border border-blue-500 h-fit flex flex-col rounded-md p-3 bg-blue-50 pt-2 mt-3 mb-3'>
						<p className={'px-4 pl-1 text-[0.7em] text-gray-700 tracking-wider uppercase font-secondary mb-2'}>Actual</p>
						<div className='flex gap-2'>
							<div className='flex flex-col'>
								<p className='text-p3 px-2 py-1 bg-blue-400 rounded-lg text-white text-center w-fit whitespace-nowrap'>
									{date}
								</p>
							</div>
							<div className='flex flex-col'>
								<p className='text-p3 px-2 py-1 bg-blue-400  rounded-lg text-white text-center w-fit whitespace-nowrap'>
									{truncateLabel(room, 11)}
								</p>
							</div>
							<div className='flex flex-col'>
								<p className='text-p3 px-2 py-1 bg-blue-400 rounded-lg text-white text-center w-fit whitespace-nowrap'>
									{start} - {end}
								</p>
							</div>
						</div>
						<div className='flex gap-2 mt-4'>
							<div className='flex flex-col'>
								<p className='text-p3 px-2 py-1 bg-blue-400  rounded-lg text-white text-center w-fit whitespace-nowrap'>
									{truncateLabel(surgeon, 17)}
								</p>
							</div>
							<Tooltip content={procedure}>
								<div className='flex flex-col'>
									<p className='text-p3 px-2 py-1 bg-blue-400  rounded-lg text-white text-center w-fit whitespace-nowrap'>
										{truncateLabel(procedure, 20)}
									</p>
								</div>
							</Tooltip>
						</div>
					</div>

					<div>
						<Select
							sizeX='full'
							label={'Delay Reason'}
							options={delay_reasons.map(({ id, name }) => ({ value: id, label: name }))}
							value={
								delay_reasons
									.map(({ id, name }) => ({ value: id, label: name }))
									.filter((option) => option.label === delay_reason_state)[0] ?? null
							}
							onChange={(selection) => {
								if (!selection) return;
								setIsDirty(true);
								setDelayReasonState(selection.label);
							}}
						/>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

function ScheduleRecordDialog({
	children,
	date,
	room,
	surgeon,
	procedure,
	start,
	end,
	elements_template,
	saved_case_elements,
	surgeon_id,
	case_order,
	room_id,
}: {
	children: React.ReactNode;
	date: string;
	room: string;
	surgeon: string;
	surgeon_id: number;
	case_order: number;
	room_id: number;
	start: string;
	end: string;
	procedure: string;
	saved_case_elements: typeof defaultCaseElements;
	elements_template: typeof defaultCaseElementsTemplate;
}) {
	const [currentCaseElements, setCurrentCaseElements] = useState(saved_case_elements);
	const [openViewModal, setOpenViewModal] = useState(false);
	const { createToast } = useToast();
	const [submitted, setSubmitted] = useState(false);
	const [setScheduleCaseElements, { status }] = useSetScheduledCaseElementsMutation();

	const handleSave = async () => {
		setSubmitted(true);
		const response = await setScheduleCaseElements({
			room_id: room_id,
			surgeon_id: surgeon_id,
			case_order: case_order,
			surgery_date: date,
			metadata: currentCaseElements,
			procedure_description: procedure,
		});

		handleApiResponse(response, {
			success: (payload) => {
				createToast({
					title: 'Case Elements Saved',
					variant: 'success',
				});
				setSubmitted(false);

				// last thing to execute
				setTimeout(() => {
					setOpenViewModal(false);
				}, 0);
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
				setSubmitted(false);
			},
		});
	};

	// refresh from endpoint
	useEffect(() => {
		setCurrentCaseElements(saved_case_elements);
	}, [saved_case_elements]);

	return (
		<Dialog
			open={openViewModal}
			actionButtons={[
				{
					onClick: () => {
						handleSave();
					},
					children: 'Confirm',
					isWorking: submitted,
				},
				{
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onClick: () => {
						setOpenViewModal(false);
					},
					children: 'Exit',
					disabled: submitted,
				},
			]}
			sizeX='lg'
			title={
				<div className='flex flex-wrap font-regular gap-2 text-p3 my-4'>
					<div className='flex flex-col'>
						<div className='text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary'>Date</div>
						<div className='text-p2 px-2 py-1 bg-gray-50 rounded-lg text-gray-500 text-center w-fit'>
							{new Date(date).toLocaleDateString()}
						</div>
					</div>

					<div className='flex flex-col'>
						<div className='ml-1 text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary'>Room</div>
						<div className='text-p2 px-2 py-1 bg-gray-50 rounded-lg text-gray-500 text-center w-fit'>{room}</div>
					</div>

					<div className='flex flex-col'>
						<div className='ml-1 text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary'>Start/End</div>
						<div className='text-p2 px-2 py-1 bg-gray-50 rounded-lg text-gray-500 text-center w-fit'>{`${start} - ${end}`}</div>
					</div>

					<div className='flex flex-col'>
						<div className='ml-1 text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary'>Surgeon</div>
						<div className='text-p2 px-2 py-1 bg-gray-50 rounded-lg text-gray-500 text-center w-fit'>
							{truncateLabel(surgeon, 40)}
						</div>
					</div>
				</div>
			}
			minimalHeaderStyle
			trigger={children}
			// open={dialogOpen}
			onOpenChange={async (isBeingOpened) => {
				if (isBeingOpened) {
					setOpenViewModal(true);
				} else {
					setOpenViewModal(false);
				}
			}}
		>
			<div
				className={classNames(
					{ 'max-h-72': window.innerHeight < 600, 'max-h-96': window.innerHeight > 600 },
					'overflow-y-auto '
				)}
			>
				<div className='flex flex-col p-5'>
					<div className='text-p3 text-gray-700 tracking-wider uppercase mb-2 font-secondary'>Essential Elements</div>
					<div className='border border-red-500 p-4 w-100 rounded-md flex flex-wrap gap-1 justify-start mb-4 text-p2 text-gray-500'>
						{elements_template.required.map((value, index) => (
							<Checkbox
								onChange={(event) => {
									const isChecked = event.target.checked;
									const thisValue = value.replace(' ', '_');
									if (isChecked) {
										if (!currentCaseElements.checked.includes(thisValue)) {
											setCurrentCaseElements({
												...currentCaseElements,
												checked: [...currentCaseElements.checked, thisValue],
											});
										}
									} else {
										setCurrentCaseElements({
											...currentCaseElements,
											checked: currentCaseElements.checked.filter((elem) => elem !== thisValue),
										});
									}
								}}
								key={index}
								label={value}
								value={value.replace(' ', '_')}
								checked={currentCaseElements.checked.includes(value.replace(' ', '_'))}
								className='w-64'
							/>
						))}
					</div>

					<div className='text-p3 text-gray-700 tracking-wider uppercase mb-2 font-secondary'>Optional Case Elements</div>
					<div className='border border-blue-500 p-4 w-100 rounded-md flex flex-wrap gap-1 justify-start mb-4 text-p2 text-gray-500'>
						{elements_template.optional.map((value, index) => (
							<Checkbox
								key={index}
								label={value}
								value={value.replace(' ', '_')}
								checked={currentCaseElements.checked.includes(value.replace(' ', '_'))}
								onChange={(event) => {
									const isChecked = event.target.checked;
									const thisValue = value.replace(' ', '_');
									if (isChecked) {
										if (!currentCaseElements.checked.includes(thisValue)) {
											setCurrentCaseElements({
												...currentCaseElements,
												checked: [...currentCaseElements.checked, thisValue],
											});
										}
									} else {
										setCurrentCaseElements({
											...currentCaseElements,
											checked: currentCaseElements.checked.filter((elem) => elem !== thisValue),
										});
									}
								}}
								className='w-64'
							/>
						))}
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default Dashboard;
