import { Link, Location } from 'react-router-dom';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Tooltip } from 'react-tooltip';
import { useEffect, useState } from 'react';
import { Parser } from 'json2csv';

import { FacilityLicense, Routes, UserRole } from 'models';
import { checkLicenseBits, env, intersects } from 'utils';
import { handleApiResponse, ShortOption, useSystem } from 'store';
import TextField from 'components/TextField';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { MultiSelect } from 'components/Select';
import { Datepicker } from 'components/Datepicker';
import { useFilters } from 'context';
import FilterFields from 'components/FilterFields';
import { useRequestBlockForecastMutation } from 'store/services/BlockService';
import ToggleGroup from 'components/ToggleGroup';
import NumberField from 'components/NumberField';
import { BlockForecastTab } from 'components/BlockForecast';
import { DataExport } from 'components/DataExport';

export interface NavMenuProps {
	menu: NavigationMenu;
	location: Location;
	hovering: boolean;
	facility_license?: string;
	has_schedule?: boolean;
}

export interface NavigationItem {
	name: string;
	href: string;
	icon: string;
	component?: string;
	required_license?: number;
	children?: NavigationItem[];
	schedule_required?: boolean;
}

export interface NavigationMenu {
	items: NavigationItem[];
}

export function NavMenu({ menu, location, hovering, facility_license, has_schedule }: NavMenuProps) {
	const { data } = useSystem();
	const isAdmin = data?.user.role === UserRole.admin;
	const earlyAccessPages = data?.user.page_list ?? [];

	return (
		<nav>
			<ul>
				{menu.items.map((item: NavigationItem, index: number) => {
					const children = item.children ?? [];
					const children_href_list = children.map((child) => child.href);
					const isCurrentPage = location.pathname === item.href;
					const isCurrentPageParent = children?.find((s) => location.pathname === s.href);
					const validatedByLicenseType = facility_license
						? checkLicenseBits(facility_license, item?.required_license ?? -1)
						: false;
					const validatedByScheduleData = item.schedule_required ? has_schedule === item.schedule_required : true;
					const validatedByPageList =
						intersects(children_href_list, earlyAccessPages) || earlyAccessPages.includes(item.href);
					const allowAccess = (validatedByLicenseType && validatedByScheduleData) || validatedByPageList;
					const showChildren = children.length > 0 && (isCurrentPage || isCurrentPageParent) && allowAccess;

					if (item.href === Routes.STYLEGUIDE && !env('SHOW_STYLEGUIDE', true)) return null;
					if (item.href === Routes.ANESTHESIA_STAFFING && !isAdmin && !validatedByPageList) return null;
					if (item.href === Routes.NURSE_STAFFING && !isAdmin && !validatedByPageList) return null;
					if (item.href === Routes.ANESTHESIA && !isAdmin && !validatedByPageList) return null;
					if (item.component && item.component === 'block-forecast' && !isAdmin) return null;
					if (item.component ? item.component === 'block-forecast' : false)
						return (
							<BlockForecastTab
								isCurrentPage={false}
								isCurrentPageParent={false}
								allowAccess
								icon={item.icon}
								name={item.name}
								hovering={hovering}
							/>
						);

					if (item.component && item.component === 'data-export' && !isAdmin) return null;
					if (item.component ? item.component === 'data-export' : false)
						return (
							<DataExport
								isCurrentPage={false}
								isCurrentPageParent={false}
								allowAccess
								icon={item.icon}
								name={item.name}
								hovering={hovering}
							/>
						);

					return (
						<li key={index} className='group'>
							<Link
								to={allowAccess ? item.href : location.pathname}
								className={classNames(
									'flex items-center gap-3 text-blue-700 pl-8 whitespace-nowrap text-sm py-3 my-2 transition-colors',
									(isCurrentPage || isCurrentPageParent) && allowAccess
										? 'bg-blue-50 border-r-4 border-r-blue-500'
										: allowAccess
										? 'hover:bg-blue-50 font-regular'
										: 'font-regular opacity-50',
									!allowAccess ? 'cursor-not-allowed' : ''
								)}
							>
								<span className='material-symbol'>{item.icon}</span>
								<span className={classNames('transition-opacity duration-300', hovering ? 'opacity-1' : 'opacity-0')}>
									{item.name}
								</span>
							</Link>
							{showChildren && (
								<ul className='my-4'>
									{children.map((child, childIndex) => {
										if (item.href === Routes.STYLEGUIDE && !env('SHOW_STYLEGUIDE', true)) return null;
										if (item.href === Routes.ANESTHESIA_STAFFING && !isAdmin && !validatedByPageList) return null;
										if (item.href === Routes.ANESTHESIA && !isAdmin && !validatedByPageList) return null;
										if (child.schedule_required === true && has_schedule === false) return null;

										// for early access, if facility does not have license but user has special access
										if (!validatedByLicenseType && !earlyAccessPages.includes(child.href)) return null;

										return (
											<li key={childIndex}>
												<Link
													to={child.href}
													className={classNames(
														'flex items-center gap-4 border-0 text-p2 pl-9 py-1 my-1',
														'transition-[opacity,transform,color] whitespace-nowrap hover:font-bold',
														hovering && 'text-blue-700 translate-x-3',
														location.pathname === child.href ? 'text-blue-700 font-bold' : 'font-regular',
														location.pathname !== child.href && !hovering && 'text-bluegray-200'
													)}
												>
													<span className='material-symbol-sm'>{child.icon}</span>
													<span className={classNames('transition-opacity duration-300', hovering ? 'opacity-1' : 'opacity-0')}>
														{child.name}
													</span>
												</Link>
											</li>
										);
									})}
								</ul>
							)}
						</li>
					);
				})}
			</ul>
		</nav>
	);
}

export default NavMenu;
