import { ApiEndpoints } from 'models/api';
import { ShortOption } from 'store';
import { standardApi } from 'store/api';

export interface ProcedurePatternObject {
	groupValue: number;
	values: {
		label: string;
		pattern: {
			procedure_end: number;
			procedure_start: number;
			procedure_time: number;
		};
	}[];
}

export interface ProcedurePatternsResponse {
	data: ProcedurePatternObject[];
}

export interface ProcedurePatternsRequest {
	facility_id: number | null;
	healthsystem_id: number | null;
	sortby: string;
	groupby: string;
	viewby: string;
	mode: string;
	filters: {
		days_of_week: string[];
		start_date: string;
		end_date: string;
		surgeons: ShortOption[];
		service_lines: ShortOption[];
		encounter_types: ShortOption[];
		rooms: ShortOption[];
		procedures: string[];
		primetime: string;
		add_ons: string;
	};
}

/**
 * Provides endpoints for retrieving procedure patterns
 */
export const procedurePatternsApi = standardApi.injectEndpoints({
	endpoints: (build) => ({
		getProcedurePatterns: build.query<ProcedurePatternsResponse, ProcedurePatternsRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_PROCEDURE_PATTERN,
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useGetProcedurePatternsQuery } = procedurePatternsApi;

export const {
	endpoints: { getProcedurePatterns },
} = procedurePatternsApi;
