import { Facility, Healthsystem, UserInfo } from 'models/models';
import { ApiEndpoints } from 'models/api';
import { standardApi } from 'store/api';

export interface ShortFacility {
	facility_name: string;
	license: string;
}

export interface AddSystemApiRequest {
	healthsystem_name: string;
	street_address?: string | null;
	state?: string | null;
	ehr?: string | null;
	cms_designation?: string | null;
	number_of_beds?: number | null;
	city?: string | null;
	zip?: number | null;
	facilities: ShortFacility[];
}

export interface AddSystemApiResponse {
	healthsystem_id: number;
	healthsystem_name: string;
}

export interface UpdateSystemApiResponse {
	success: boolean;
}

export interface UpdateSystemApiRequest {
	id: number;
	name: string;
	street_address?: string | null;
	state?: string | null;
	city?: string | null;
	zip?: number | null;
}

export interface InitStateApiResponse {
	default_facility_configured: boolean;
	default_facility_empty: boolean;
	facilities: Facility[];
	healthsystems: Healthsystem[];
	user: UserInfo;
}

export interface GetDashboardResponse {
	data_upload_max_date: Date;
	is_holiday: boolean;
	fcots_rate: number;
	turnover: number;
	volume: number;
}

const apiWithTags = standardApi.enhanceEndpoints({
	addTagTypes: ['Core', 'System', 'FacilityConfig', 'FacilitySettings'],
});

/**
 * Provides endpoints for retrieving initial state (healthsystems, facilities) and creating new healthsystems
 */
export const initApi = apiWithTags.injectEndpoints({
	endpoints: (build) => ({
		getSystems: build.query<InitStateApiResponse, { healthsystem_id?: number | null }>({
			query: ({ healthsystem_id }) => ({
				url: ApiEndpoints.INIT,
				params: { healthsystem_id: healthsystem_id !== null ? healthsystem_id : undefined },
			}),
			providesTags: ['System', 'FacilityConfig', 'FacilitySettings'],
		}),
		addSystem: build.mutation<AddSystemApiResponse, AddSystemApiRequest>({
			query: (body) => ({
				url: ApiEndpoints.CREATE_HEALTHSYSTEM,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['System'],
		}),
		updateSystem: build.mutation<UpdateSystemApiResponse, UpdateSystemApiRequest>({
			query: (body) => ({
				url: ApiEndpoints.UPDATE_HEALTHSYSTEM,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['System'],
		}),
		getDashboard: build.query<GetDashboardResponse, { facility_id?: number | null }>({
			query: ({ facility_id }) => ({
				url: ApiEndpoints.DASHBOARD,
				params: { facility_id: facility_id !== null ? facility_id : undefined },
			}),
			providesTags: ['Core'],
		}),
	}),
});

export const { useGetSystemsQuery, useAddSystemMutation, useGetDashboardQuery, useUpdateSystemMutation } = initApi;

export const {
	endpoints: { getSystems, addSystem, getDashboard, updateSystem },
} = initApi;
