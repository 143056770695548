import { ApiEndpoints } from 'models/api';
import { BlockPatternPreviewObject, ShortOption } from 'store';
import { standardApi } from 'store/api';
import { number } from 'yup';

export type BlockDetailItem = {
	id: number;
	block_id: number;
	date: Date;
	allocated: number;
	in_block: number;
	out_block: number;
	turnover: number;
	block_turnover: number;
	flip_count: number;
	total_cases: number;
	add_on_vol: number;
	voluntary_release: number;
	automatic_release: number;
	unique_rooms_used: number;
	utilization: number;
	day_of_week: string;
};

export type BlockScorecardResponseItem = {
	block_name: string;
	start_date: Date;
	end_date: Date;
	utilization_target_percent: number;
	aggregate_util_percentage: number;
	aggregate_block_utilization_dow: { x: string; y: number }[];
	release_patterns: {
		x: 'Allocated' | 'In Block' | 'Out Block' | 'Block Turnover' | 'Voluntary Release' | 'Automatic Release';
		y: number;
	}[];
	block_details: BlockDetailItem[];
	surgeon_block_minutes_contributions: { x: string; y: number }[];

	block_schedule: BlockPatternPreviewObject[];
};

export type BlockScorecardResponse = BlockScorecardResponseItem[];

export interface BlockScorecardRequest {
	facility_id: number | null;
	healthsystem_id: number | null;
	block_id: number[];
	groupBy: string;
	filters: {
		days_of_week: string[];
		utilization_type: string;
		procedures?: string[];
		turnover_threshold: number;
		start_date: string;
		end_date: string;
		abandoned_days_toggle: string;
	};
}

export interface BlockScheduleItem {
	date: Date;
	schedule_id: number;
	block_id: number;
	block_name?: string;
	block_pattern_id: number;
	dow: string;
	allocated_time: number;
	block_type: string;
	flip_room: boolean;
	rooms: BlockScheduleRoom[];
}

export interface ReleaseLogItem {
	date: Date;
	date_released: Date;
	release_id: number;
	block_pattern_id: number;
	block_pattern_room_id: number;
	block_pattern_room_release_id: number;
	room_number: string;
	start_time: string;
	end_time: string;
	dow: string;
	released_minutes: number;
	block_id: number;
	block_name: string;
	release_type: string;
	bulk_release_indicator: number;
}

export interface BulkReleaseItem {
	date: Date;
	day_of_week: string;
	date_released: Date;
	number_of_blocks_released: number;
	detail: ReleaseDetailItem[];
}

export interface ReleaseDetailItem {
	block_name: string;
	released_minutes: number;
	number_of_surgeons: number;
}

export interface BlockScheduleRoom {
	block_pattern_room_id: number;
	room_number: number;
	start: string;
	end: string;
	newStart?: string;
	newEnd?: string;
	released?: boolean;
	is_full_release?: boolean;
	flag?: 'full_release' | undefined;
}

const blocks = standardApi.enhanceEndpoints({ addTagTypes: ['Core', 'Blocks'] });

/**
 * Provides endpoints for data values in database
 */
export const blockScorecardService = blocks.injectEndpoints({
	endpoints: (build) => ({
		getBlockScorecard: build.query<BlockScorecardResponse, BlockScorecardRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_BLOCK_SCORECARD,
				method: 'POST',
				body,
			}),
			providesTags: ['Core', 'Blocks'],
		}),
		getBlockSchedule: build.query<
			{ blocks: BlockScheduleItem[] },
			{
				facility_id: number | null;
				healthsystem_id: number | null;
				block: ShortOption | null;
				previous_blocks: boolean;
				flip_room_blocks: boolean;
				filters: {
					days_of_week: string[];
					utilization_type: string;
					turnover_threshold: number;
					start_date: string;
					end_date: string;
				};
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_BLOCK_SCHEDULE,
				method: 'POST',
				body,
			}),
			providesTags: ['Blocks'],
		}),
		getVoluntaryReleaseLog: build.query<
			{ log: ReleaseLogItem[] },
			{
				facility_id: number | null;
				healthsystem_id: number | null;
				block: ShortOption | null;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_BLOCK_RELEASE_LOG,
				method: 'POST',
				body,
			}),
			providesTags: ['Blocks'],
		}),
		getBulkReleaseLog: build.query<
			{ log: BulkReleaseItem[] },
			{
				facility_id: number | null;
				healthsystem_id: number | null;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_BULK_RELEASE_LOG,
				method: 'POST',
				body,
			}),
			providesTags: ['Blocks'],
		}),
		setBulkRelease: build.mutation<string, { healthsystem_id: number | null; facility_id: number | null; date: string }>({
			query: (body) => ({
				url: ApiEndpoints.SET_BULK_RELEASE,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Blocks'],
		}),
		setVoluntaryRelease: build.mutation<
			string,
			{ healthsystem_id: number | null; facility_id: number | null; data: BlockScheduleItem[] }
		>({
			query: (body) => ({
				url: ApiEndpoints.SET_BLOCK_RELEASE,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Blocks'],
		}),
		undoVoluntaryRelease: build.mutation<string, { healthsystem_id: number | null; data: ReleaseLogItem[] }>({
			query: (body) => ({
				url: ApiEndpoints.SET_UNDO_BLOCK_RELEASE,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Blocks'],
		}),
		undoBulkRelease: build.mutation<
			string,
			{ healthsystem_id: number | null; facility_id: number | null; dates: string[] }
		>({
			query: (body) => ({
				url: ApiEndpoints.SET_UNDO_BULK_RELEASE,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Blocks'],
		}),
	}),
});

export const {
	useGetBlockScorecardQuery,
	useGetBlockScheduleQuery,
	useGetVoluntaryReleaseLogQuery,
	useGetBulkReleaseLogQuery,
	useSetBulkReleaseMutation,
	useSetVoluntaryReleaseMutation,
	useUndoVoluntaryReleaseMutation,
	useUndoBulkReleaseMutation,
} = blockScorecardService;

export const {
	endpoints: {
		getBlockScorecard,
		getBlockSchedule,
		getVoluntaryReleaseLog,
		getBulkReleaseLog,
		setBulkRelease,
		setVoluntaryRelease,
		undoVoluntaryRelease,
		undoBulkRelease,
	},
} = blockScorecardService;
