import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

import { DataTable, DateToggle, ExportButton, LogoOverlay, PageHeading } from 'components';
import { convertToUTC } from 'utils';
import { useAppSelector } from 'store';
import { useFilters } from 'context';
import { useGetCaseDetailsQuery } from 'store/services/CaseDetailsService';
import { Timeline } from 'pages';

export function BlockScorecardCaseDetails() {
	const [searchParams] = useSearchParams();
	const block_detail_id = parseInt(searchParams.get('block_detail_id') ?? '') ?? null;
	const [blockDate, setBlockDate] = useState(new Date(searchParams.get('block_date') ?? ''));
	const { selectedSystem, selectedFacility } = useAppSelector((state) => state.userState);
	const { date, rooms } = useFilters();
	const [utilType] = useState(searchParams.get('util_type') ?? '') ?? null;
	const [turnoverTimeThreshold] = useState(searchParams.get('turnover_threshold') ?? '60') ?? null;
	const [blockName] = useState(searchParams.get('block_name') ?? '') ?? null;
	const [procedures] = useState(searchParams.get('procedures') ?? '') ?? null;
	const { data, isFetching } = useGetCaseDetailsQuery({
		healthsystem_id: selectedSystem,
		facility_id: selectedFacility,
		block_detail_id: block_detail_id,
		block_date: convertToUTC(blockDate),
		utilization_type: utilType,
		turnover_threshold: turnoverTimeThreshold,
		procedures: [procedures],
	});

	// if there is case detail
	const isCaseData = data?.caseDetails ? data?.caseDetails.length : '';
	const caseDetailRoomNames = data?.caseDetails.map((row) => row.room);
	const caseDetailRoomIds = rooms.all.filter((row) => caseDetailRoomNames?.includes(row.name));
	const caseDetailSurgeonNames = data?.caseDetails.map((row) => row.surgeon);
	return (
		<div className='w-100'>
			<PageHeading useURL={true}>
				{blockDate.toLocaleString('en-TT', {
					month: 'short',
					day: '2-digit',
					year: 'numeric',
					weekday: 'short',
				}) +
					' - ' +
					blockName}{' '}
				Case Details
			</PageHeading>

			<div className='flex justify-end pb-3'>
				<ExportButton contents={data?.caseDetails ?? []} />
			</div>

			{isCaseData ? <MetricPanelBlue data={data?.panelMetrics ?? []} /> : ''}
			{isFetching && <LogoOverlay backgroundColor='white' />}
			<div className='h-4'></div>
			<DataTable
				title='Case Details Log'
				headerContentCenter={
					<DateToggle
						key={date?.applied.getTime()}
						selected={blockDate ?? date?.applied}
						onApply={(d) => {
							setBlockDate(d);
						}}
						popoverContentProps={{ side: 'right' }}
					/>
				}
				columns={[
					{
						accessorKey: 'facility_name',
						header: 'Facility Name',
						enableGlobalFilter: false,
						meta: {
							headerClass: 'whitespace-pre-line',
						},
					},
					{
						accessorKey: 'room',
						header: 'Room',
					},
					{
						accessorKey: 'surgeon',
						header: 'Surgeon',
					},
					{
						accessorKey: 'procedure',
						header: 'Procedure',
					},
					{
						accessorKey: 'patient_in',
						header: 'Patient In',
						enableGlobalFilter: false,
					},
					{
						accessorKey: 'patient_out',
						header: 'Patient Out',
						enableGlobalFilter: false,
					},
					{
						accessorKey: 'duration',
						header: 'Duration',
						enableGlobalFilter: false,
						cell: ({ row }) => {
							return row.original.duration + 'm';
						},
					},
					{
						accessorKey: 'in_block_minutes',
						header: 'In Block',
						enableGlobalFilter: false,
						cell: ({ row }) => {
							return row.original.in_block_minutes + 'm';
						},
					},
					{
						accessorKey: 'out_block_minutes',
						header: 'Out Block',
						enableGlobalFilter: false,
						cell: ({ row }) => {
							return row.original.out_block_minutes + 'm';
						},
					},
					{
						accessorKey: 'turnover',
						header: 'Turnover',
						enableGlobalFilter: false,
						cell: ({ row }) => {
							return row.original.turnover + 'm';
						},
					},
					{
						accessorKey: 'block_turnover',
						header: 'Block Turnover',
						enableGlobalFilter: false,
						cell: ({ row }) => {
							return row.original.block_turnover + 'm';
						},
						meta: {
							headerClass: 'whitespace-pre-line',
						},
					},
					{
						accessorKey: 'flipped_indicator',
						header: 'Flip',
						enableGlobalFilter: false,
						cell: ({ row }) => {
							if (row.original.flipped_indicator) {
								return <span className='material-symbol opacity-50'>check</span>;
							} else {
								return '';
							}
						},
					},
					{
						accessorKey: 'is_add_on',
						header: 'Add-on',
						enableGlobalFilter: false,
						cell: ({ row }) => {
							if (row.original.is_add_on) {
								return <span className='material-symbol opacity-50'>check</span>;
							} else {
								return '';
							}
						},
					},
				]}
				data={data?.caseDetails ?? []}
			/>
			<div className='h-10'></div>
			{caseDetailRoomIds.length > 0 && (
				<Timeline
					hideDatePicker={true}
					hideFilters={true}
					defaultProcedures={[procedures]}
					parentDate={blockDate
						.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' })
						.replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')}
					parentRooms={caseDetailRoomIds}
					parentSurgeons={caseDetailSurgeonNames}
				/>
			)}
		</div>
	);
}

function MetricPanelBlue({ data }: { data: { value: number; name: string }[] }) {
	return (
		<div className='w-full bg-blue-50 p-5 flex flex-wrap justify-around rounded-sm'>
			{data.map((value, i) => (
				<div key={i} className='bg-white p-2 border border-blue-400 m-2 w-1/5 px-5 rounded-sm'>
					<div>
						<p className='text-h2 font-bold'>{value.value !== -1 ? value.value : '---'}</p>
						<div>
							<p className='text-gray-500 text-p2 pb-1'>{value.name}</p>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

export default BlockScorecardCaseDetails;
