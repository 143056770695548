import classNames from 'classnames';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Parser } from 'json2csv';

import Dialog from 'components/Dialog';
import FilterFields from 'components/FilterFields';
import NumberField from 'components/NumberField';
import MultiSelect from 'components/Select/MultiSelect';
import ToggleGroup from 'components/ToggleGroup';
import { useFilters } from 'context';
import { handleApiResponse, ShortOption, useAppSelector, useSystem } from 'store';
import { useGetDataExportMutation } from 'store/services/DataExport';

interface DataExportProps {
	isCurrentPage: boolean;
	isCurrentPageParent: boolean;
	allowAccess: boolean;
	icon: string;
	name: string;
	hovering: boolean;
}

export function DataExport({ isCurrentPage, isCurrentPageParent, allowAccess, icon, name, hovering }: DataExportProps) {
	const [requestDownloadLink] = useGetDataExportMutation();
	const { dateRange } = useFilters();
	const [openViewModal, setOpenViewModal] = useState(false);

	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data: facility_data } = useSystem();
	const system = facility_data?.healthsystems.find((h) => h.id === selectedSystem);

	const [selectedFacilities, setSelectedFacilities] = useState(
		facility_data?.facilities
			.filter((f) => !f.name.includes('*'))
			.map((f) => {
				return { value: f.id, label: f.name };
			})
	);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setSelectedFacilities(
			facility_data?.facilities
				.filter((f) => !f.name.includes('*'))
				.map((f) => {
					return { value: f.id, label: f.name };
				})
		);
	}, [facility_data]);

	const submitExportRequest = async () => {
		setLoading(true);
		const response = await requestDownloadLink({
			file_name_prefix: system?.name ?? 'NONE',
			filters: {
				start_date: format(dateRange.selected.startDate, 'MM/dd/yyyy'),
				end_date: format(dateRange.selected.endDate, 'MM/dd/yyyy'),
			},

			facility_ids: selectedFacilities?.map((f) => f.value) ?? [],
		});

		handleApiResponse(response, {
			success: (payload) => {
				// Automatically open the download link
				const link = document.createElement('a');
				link.href = payload.download_link;
				link.click();

				// update states
				setLoading(false);
				setTimeout(() => {
					setOpenViewModal(false);
				}, 0);
			},
			error: (payload) => {
				setLoading(false);
			},
		});
	};

	return (
		<div className=''>
			<Dialog
				sizeX='lg'
				title='Data Export'
				subtitle='Use this tool to export raw data for the health system from our database.'
				trigger={
					<div
						className={classNames(
							'cursor-pointer flex items-center gap-3 text-blue-700 pl-8 whitespace-nowrap text-sm py-3 my-2 transition-colors',
							(isCurrentPage || isCurrentPageParent) && allowAccess
								? 'bg-blue-50 border-r-4 border-r-blue-500'
								: allowAccess
								? 'hover:bg-blue-50 font-regular'
								: 'font-regular opacity-50',
							!allowAccess ? 'cursor-not-allowed' : ''
						)}
					>
						<span className='material-symbol'>{icon}</span>
						<span className={classNames('transition-opacity duration-300', hovering ? 'opacity-1' : 'opacity-0')}>
							{name}
						</span>
					</div>
				}
				actionButtons={[
					{
						children: 'Download',
						isWorking: loading,
						onClick: () => {
							submitExportRequest();
						},
					},
					{
						children: 'Cancel',
						onClick: () => setOpenViewModal(false),
					},
				]}
				open={openViewModal}
				onOpenChange={(isBeingOpened) => {
					if (isBeingOpened) {
						setOpenViewModal(true);
					} else {
						setOpenViewModal(false);
					}
				}}
			>
				<div className='flex overflow-auto'>
					<div className='flex flex-col pt-5 text-[0.8em] text-gray-500 italic'>
						<div className='bg-blue-50 p-2 rounded-md flex w-fit mt-2'>
							<p className='text-blue-500 font-semibold text-[1em] pr-1 not-italic'>1</p>
							<p>Which facilities are included in the data export?</p>
						</div>

						<div className='bg-blue-50 p-2 rounded-md flex w-fit mt-[5em]'>
							<p className='text-blue-500 font-semibold text-[1em] pr-1 not-italic'>2</p>
							<p>What date range would you like included in the export?</p>
						</div>
					</div>
					<div className='flex flex-col w-[20em]'>
						<div className='px-4'>
							<MultiSelect
								label={'Included Facilities'}
								options={
									facility_data?.facilities
										.filter((f) => !f.name.includes('*'))
										.map((f) => {
											return { value: f.id, label: f.name };
										}) ?? []
								}
								onChange={(selections) => {
									setSelectedFacilities(selections.map(({ value: id, label: name }) => ({ value: id, label: name })));
								}}
								value={selectedFacilities}
							/>
						</div>

						<div className='mt-4'>
							<FilterFields fields={['dateRange']} />
						</div>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
