import * as yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as RadixToggleGroup from '@radix-ui/react-toggle-group';
import { set } from 'cypress/types/lodash';

import {
	Accordion,
	Button,
	ButtonLink,
	LicenseToggleGroup,
	MultiSelect,
	Select,
	TextArea,
	TextField,
	ToggleGroup,
} from 'components';
import {
	cms_designations,
	createLicenseBits,
	ehr_systems,
	getFacilityName,
	inputFieldRegex,
	requireFieldValueForAnesthesia,
	requireFieldValueWhenCloning,
	requireValidFieldValueWhenCloning,
	us_states,
} from 'utils';
import {
	getQueryError,
	handleApiResponse,
	useAddFacilityMutation,
	useAppSelector,
	useDeleteArtificialFacilityMutation,
	useGetChildFacilitiesQuery,
	useSetFacilityConfigMutation,
} from 'store';
import { ToggleButton } from 'pages/Block';
import { useToast } from 'context';
import { LoadingIndicator } from 'components/Select/subcomponents';
import { type Facility, FacilityLicense, type FieldMapping, Healthsystem, RecordType, type Room } from 'models';

import type { CreateFacilityRequest, SetFacilityConfigurationRequest } from 'store';

export interface FacilityConfigAccordionItemProps {
	facility?: Facility;
	otherFacilities: Facility[];
	providedSystem?: Healthsystem | null;
}

export const scheduleRecordTypes = [
	{
		name: 'SCHEDULED',
		description: 'Categorical description to identify a case was scheduled',
	},
	{
		name: 'MOVED',
		description:
			'Categorical description to identify a case that was scheduled and then moved to a different time or day',
	},
	{
		name: 'SWAPPED OR',
		description: 'Categorical description to identify a case that was scheduled and then moved to a different OR',
	},
];

const schedulingDataRequiredFields = [
	'schedule$_case_id',
	'schedule$_line_number',
	'schedule$_record_type',
	'schedule$_case_create_date',
	'schedule$_surgery_date',
	'schedule$_procedure_start',
	'schedule$_primary_surgeon',
	'schedule$_facility',
	'schedule$_room',
	'schedule$_procedure_description',
];

function trimExtraWhitespace(str: string) {
	return str.replace(/\s+/g, ' ').trim();
}

const defaultFieldMapping: Record<keyof FieldMapping, string> = {
	client_provided_case_id: '',
	patient_in_room: '',
	patient_out_room: '',
	room: '',
	surgeon: '',
	facility: '',
	surgery_date: '',
	scheduled_start: '',
	procedure_start: '',
	procedure_stop: '',
	procedure_description: '',
	anesthesia_start: '',
	anesthesia_end: '',
	primary_anesthesiologist: '',
	primary_anesthetist: '',
	asa_class: '',
	anesthesia_ready: '',
	encounter_type: '',
	service_line: '',
	is_add_on: '',
	case_create_date: '',
	case_cancel_date: '',
	scheduled_stop: '',
	delay_code: '',
	delay_type: '',
	delay_reason: '',
	delay_duration: '',
	scheduled_room: '',
	cpt_code: '',
	cpt_description: '',
	primary_payor: '',
	primary_circulator: '',
	primary_cst: '',
	primary_first_assist: '',
	preop_in: '',
	pacu_p1_in: '',
	pacu_p1_out: '',
	pacu_p2_in: '',
	pacu_p2_out: '',
	schedule$_case_id: '',
	schedule$_line_number: '',
	schedule$_record_type: '',
	schedule$_cancel_date: '',
	schedule$_service_line: '',
	schedule$_case_create_date: '',
	schedule$_surgery_date: '',
	schedule$_procedure_start: '',
	schedule$_procedure_stop: '',
	schedule$_duration: '',
	schedule$_primary_surgeon: '',
	schedule$_facility: '',
	schedule$_room: '',
	schedule$_procedure_description: '',
};

const fieldMappingData: Record<
	keyof FieldMapping,
	{ required: boolean; label: string; name: string; license_tag?: string; type?: string; required_message?: string }
> = {
	client_provided_case_id: {
		required: false,
		label: 'Client provided case identifier that will be used to link scheduled cases to intraoperative records.',
		name: 'Case ID',
	},
	patient_in_room: {
		required: true,
		label: 'Time the patient was wheeled into the operating room. Also known as "wheels in" time.',
		name: 'Patient In Room',
	},
	patient_out_room: {
		required: true,
		label: 'Time the patient was wheeled out of the operating room. Also known as "wheels out" time.',
		name: 'Patient Out Room',
	},
	room: {
		required: true,
		label: 'Name of the operating room where the surgical case occurred.',
		name: 'Room',
	},
	surgeon: {
		required: true,
		label: 'Name of the primary surgeon performing the surgery.',
		name: 'Surgeon',
	},
	facility: {
		required: true,
		label: 'Name of the facility where the surgical case occurred.',
		name: 'Facility',
	},
	scheduled_start: {
		required: true,
		label: 'Time the case was scheduled to start.',
		name: 'Scheduled Start',
	},
	procedure_start: {
		required: true,
		label: 'Time the surgical procedure begins. Also known as "cut time".',
		name: 'Procedure Start',
	},
	procedure_stop: {
		required: true,
		label: 'Time the surgical procedure ends. Also known as "close time".',
		name: 'Procedure Stop',
	},

	anesthesia_start: {
		required: false, // this is dynamic and will only be required if anesthesia license is selected
		label: 'Date and/or time the anesthesia provider begins providing anesthesia.',
		name: 'Anesthesia Start',
		license_tag: '4', // only show this field if license type is 4
	},

	anesthesia_end: {
		required: false,
		label: 'Date and/or time the anesthesia provider stops providing anesthesia.',
		name: 'Anesthesia End',
		license_tag: '4',
	},
	primary_anesthesiologist: {
		required: false,
		label: 'Name of primary anesthesiologist for the case.',
		name: 'Primary Anesthesiologist',
		license_tag: '4',
	},

	primary_anesthetist: {
		required: false,
		label: 'Name of primary anesthestist for the case.',
		name: 'Primary Anesthetist',
	},

	asa_class: {
		required: false,
		label: 'A categorical description of the patient’s health on the day of surgery.',
		name: 'ASA Class',
	},

	anesthesia_ready: {
		required: false,
		label: 'Date and/or time when pre-operative anesthesia activities are complete.',
		name: 'Anesthesia Ready',
	},

	encounter_type: {
		required: true,
		label: 'A categorical description of the patient encounter, like “Inpatient”, “Outpatient”, or “Same Day Admit”',
		name: 'Encounter Type',
	},
	service_line: {
		required: true,
		label:
			'A categorical description of the service line or specialty for the case performed, like “Orthopedics” or ”Urology”',
		name: 'Procedure Service Line',
	},
	surgery_date: {
		required: false,
		label: 'Date on which the surgical case was performed.',
		name: 'Surgery Date',
	},
	procedure_description: {
		required: false,
		label: 'Text description of the procedure(s) performed during the case.',
		name: 'Procedure Description',
	},
	is_add_on: {
		required: false,
		label: 'Indicator designating a case as an add-on.',
		name: 'Add On Indicator',
	},
	case_create_date: {
		required: false,
		label: 'Date the case record was created.',
		name: 'Case Create Date',
	},
	case_cancel_date: {
		required: false,
		label:
			'Date the case was canceled. Some cases are canceled and rescheduled, so more information is typically required for reliable cancelation information.',
		name: 'Case Cancel Date',
	},
	scheduled_stop: {
		required: false,
		label: 'Time the case was scheduled to conclude.',
		name: 'Scheduled Stop',
	},
	delay_code: {
		required: false,
		label: 'An alpha/numeric code for the type of delay that occurred.',
		name: 'Delay Code',
	},
	delay_type: {
		required: false,
		label: 'A categorical description of the delay reason, e.g. "Surgeon Late", "Patient Delay".',
		name: 'Delay Type',
	},
	delay_reason: {
		required: false,
		label:
			'A free-text description of the delay reason. This is typically used when the "Delay Type" or "Delay Code" does not adequately describe the source of the delay.',
		name: 'Delay Reason',
	},
	delay_duration: {
		required: false,
		label: 'A measurement of the time of the delay. Only integer values can be parsed if this field is mapped.',
		name: 'Delay Duration',
	},
	scheduled_room: {
		required: false,
		label:
			'The room where the case was scheduled to occur. May be different than where the case actually happened, e.g. "Room" field above.',
		name: 'Scheduled Room',
	},
	cpt_code: {
		required: false,
		label:
			'The standardized code that designates services between providers and payors. This field will represent any code category (I, II, III, etc.).',
		name: 'CPT Code',
	},
	cpt_description: {
		required: false,
		label: 'The metadata associated with the CPT Code field above.',
		name: 'CPT Description',
	},
	primary_payor: {
		required: false,
		label: 'The insurance policy or entity that pays first when a patient is covered by more than one insurance plan.',
		name: 'Primary Payor',
	},
	primary_circulator: {
		required: false,
		label: 'The name of the primary nurse circulator for the case.',
		name: 'Primary Circulator',
	},
	primary_cst: {
		required: false,
		label: 'The name of the primary CST for the case.',
		name: 'Primary CST',
	},
	primary_first_assist: {
		required: false,
		label: 'The name of the primary first assist for the case.',
		name: 'First Assist',
	},
	preop_in: {
		required: false,
		label: 'Time the patient entered preop.',
		name: 'Preop In',
	},
	pacu_p1_in: {
		required: false,
		label: 'Time the patient entered PACU Phase I.',
		name: 'PACU Phase I In',
	},
	pacu_p1_out: {
		required: false,
		label: 'Time the patient left PACU Phase I.',
		name: 'PACU Phase I Out',
	},
	pacu_p2_in: {
		required: false,
		label: 'Time the patient entered PACU Phase II.',
		name: 'PACU Phase II In',
	},
	pacu_p2_out: {
		required: false,
		label: 'Time the patient left PACU Phase II.',
		name: 'PACU Phase II Out',
	},
	schedule$_case_id: {
		required: false,
		label: 'ID assigned to case.',
		name: 'Case ID',
		type: 'schedule',
		required_message: 'Yes',
	},
	schedule$_line_number: {
		required: false,
		label: 'Line number assigned to case update.',
		name: 'Line Number',
		type: 'schedule',
		required_message: 'Yes',
	},
	schedule$_record_type: {
		required: false,
		label: 'Record type for schedule row.',
		name: 'Record Type',
		type: 'schedule',
		required_message: 'Yes',
	},
	schedule$_case_create_date: {
		required: false,
		label: 'Date case was created.',
		name: 'Case Create Date',
		type: 'schedule',
		required_message: 'Yes',
	},

	schedule$_surgery_date: {
		required: false,
		label: 'Date on which the surgical case was performed.',
		name: 'Scheduled Surgery Date',
		type: 'schedule',
		required_message: 'Yes',
	},

	schedule$_procedure_start: {
		required: false,
		label: 'Time the surgical procedure begins. Also known as "cut time".',
		name: 'Scheduled Procedure Start',
		type: 'schedule',
		required_message: 'Yes',
	},

	schedule$_procedure_stop: {
		required: false,
		label: 'Time the surgical procedure ends. Also known as "close time".',
		name: 'Scheduled Procedure Stop',
		type: 'schedule',
		required_message: 'No',
	},

	schedule$_duration: {
		required: false,
		label: 'Scheduled duration for case.',
		name: 'Scheduled Duration',
		type: 'schedule',
		required_message: 'No',
	},

	schedule$_primary_surgeon: {
		required: false,
		label: 'Name of the primary surgeon performing the surgery.',
		name: 'Primary Surgeon',
		type: 'schedule',
		required_message: 'Yes',
	},

	schedule$_facility: {
		required: false,
		label: 'Name of the facility where the surgical case will occur.',
		name: 'Facility',
		type: 'schedule',
		required_message: 'Yes',
	},

	schedule$_room: {
		required: false,
		label: 'Name of the operating room where the surgical case will occur.',
		name: 'Room',
		type: 'schedule',
		required_message: 'Yes',
	},

	schedule$_procedure_description: {
		required: false,
		label: 'Text description of the procedure(s) performed during the case.',
		name: 'Procedure Description',
		type: 'schedule',
		required_message: 'Yes',
	},
	schedule$_cancel_date: {
		required: false,
		label: 'Date the scheduled procedure was canceled.',
		name: 'Cancel Date',
		type: 'schedule',
		required_message: 'No',
	},
	schedule$_service_line: {
		required: false,
		label:
			'A categorical description of the service line or specialty for the case performed, like “Orthopedics” or ”Urology”',
		name: 'Service Line',
		type: 'schedule',
		required_message: 'No',
	},
};

export const updateFacilitySettingsFormSchema = yup.object({
	facility_id: yup.number(),
	name: yup
		.string()
		.trim()
		.required('A facility name is required')
		.matches(inputFieldRegex, 'This field value is not valid.')
		.max(100, 'Facility name cannot be more than 100 characters.'),
	city: yup.string().nullable(),
	zip: yup.number().nullable(),
	street_address: yup.string().nullable(),
	state: yup.string().nullable(),
	ehr: yup.string().nullable(),
	cms_designation: yup.string().nullable(),
	number_of_beds: yup.number().nullable(),
	intraop_facility_name_alias: yup.string().trim().matches(inputFieldRegex, 'Display Name is not valid.').nullable(),
	license_type: yup.string().required('A license is required for each facility'),
	clone_facility_id: yup.number().nullable(),
	field_mappings: yup.object({
		client_provided_case_id: yup.string().trim(),
		patient_in_room: yup
			.string()
			.trim()
			.test('requiredIfNoCloneSelected', 'This field is required', requireFieldValueWhenCloning)
			.test('validateIfNoCloneSelected', 'This field value is not valid.', requireValidFieldValueWhenCloning),
		patient_out_room: yup
			.string()
			.trim()
			.test('requiredIfNoCloneSelected', 'This field is required', requireFieldValueWhenCloning)
			.test('validateIfNoCloneSelected', 'This field value is not valid.', requireValidFieldValueWhenCloning),
		room: yup
			.string()
			.trim()
			.test('requiredIfNoCloneSelected', 'This field is required', requireFieldValueWhenCloning)
			.test('validateIfNoCloneSelected', 'This field value is not valid.', requireValidFieldValueWhenCloning),
		surgeon: yup
			.string()
			.trim()
			.test('requiredIfNoCloneSelected', 'This field is required', requireFieldValueWhenCloning)
			.test('validateIfNoCloneSelected', 'This field value is not valid.', requireValidFieldValueWhenCloning),
		facility: yup
			.string()
			.trim()
			.test('requiredIfNoCloneSelected', 'This field is required', requireFieldValueWhenCloning)
			.test('validateIfNoCloneSelected', 'This field value is not valid.', requireValidFieldValueWhenCloning),
		surgery_date: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		scheduled_start: yup
			.string()
			.trim()
			.test('requiredIfNoCloneSelected', 'This field is required', requireFieldValueWhenCloning)
			.test('validateIfNoCloneSelected', 'This field value is not valid.', requireValidFieldValueWhenCloning),
		procedure_start: yup
			.string()
			.trim()
			.test('requiredIfNoCloneSelected', 'This field is required', requireFieldValueWhenCloning)
			.test('validateIfNoCloneSelected', 'This field value is not valid.', requireValidFieldValueWhenCloning),
		procedure_stop: yup
			.string()
			.trim()
			.test('requiredIfNoCloneSelected', 'This field is required', requireFieldValueWhenCloning)
			.test('validateIfNoCloneSelected', 'This field value is not valid.', requireValidFieldValueWhenCloning),
		procedure_description: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		encounter_type: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		anesthesia_start: yup
			.string()
			.trim()
			.matches(inputFieldRegex, 'This field value is not valid.')
			.test('requireFieldValueForAnesthesia', 'This field is required', requireFieldValueForAnesthesia),
		anesthesia_end: yup
			.string()
			.trim()
			.matches(inputFieldRegex, 'This field value is not valid.')
			.test('requireFieldValueForAnesthesia', 'This field is required', requireFieldValueForAnesthesia),
		primary_anesthesiologist: yup
			.string()
			.trim()
			.matches(inputFieldRegex, 'This field value is not valid.')
			.test('requireFieldValueForAnesthesia', 'This field is required', requireFieldValueForAnesthesia),
		primary_anesthetist: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		asa_class: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		anesthesia_ready: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		service_line: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		is_add_on: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		case_create_date: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		case_cancel_date: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		scheduled_stop: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		delay_code: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		delay_type: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		delay_reason: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		delay_duration: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		scheduled_room: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		cpt_code: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		cpt_description: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		primary_payor: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		primary_circulator: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		primary_cst: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		primary_first_assist: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		preop_in: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		pacu_p1_in: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		pacu_p1_out: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		pacu_p2_in: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		pacu_p2_out: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_case_id: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_line_number: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_record_type: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_cancel_date: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_service_line: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_case_create_date: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_surgery_date: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_procedure_start: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_procedure_stop: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_duration: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_primary_surgeon: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_facility: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_room: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
		schedule$_procedure_description: yup.string().trim().matches(inputFieldRegex, 'This field value is not valid.'),
	}),
	rooms: yup.array().of(
		yup.object({
			id: yup.number().nullable(),
			name: yup
				.string()
				.required('A room name is required')
				.max(100, 'A room name cannot be more than 100 characters')
				.matches(inputFieldRegex, 'This field value is not valid.'),
			licensed: yup.boolean().required('A license is required for each room'),
		})
	),
	schedule_record_types: yup.array().of(
		yup.object({
			name: yup.string().required('A record type is required').matches(inputFieldRegex, 'This field value is not valid.'),
			target: yup.string(),
		})
	),
});

// type we expect when form submitted, used to validate
export type UpdateFacilitySettingsFormInputs = yup.InferType<typeof updateFacilitySettingsFormSchema>;

export function FacilityConfigAccordionItem({
	facility,
	otherFacilities,
	providedSystem,
}: FacilityConfigAccordionItemProps) {
	const editing = facility !== undefined;
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { createToast } = useToast();
	const [showGeo, setShowGeo] = useState(false); // used to show or hide geo information for each facility
	const [hasSchedulingData, setScheduleToggleState] = useState(
		facility
			? facility.field_mapping.schedule$_case_create_date && facility.field_mapping.schedule$_primary_surgeon
			: false
	);
	const defaults = {
		facility_id: facility ? facility.id : undefined,
		name: facility ? getFacilityName(facility) : '',
		intraop_facility_name_alias: facility ? facility.intraop_facility_name_alias : undefined,
		license_type: facility ? facility.license : createLicenseBits(FacilityLicense.core),
		clone_facility_id: null,
		field_mappings: facility ? facility.field_mapping : defaultFieldMapping,
		street_address: facility ? facility.street_address : '',
		city: facility ? facility.city : '',
		state: facility ? facility.state : '',
		number_of_beds: facility ? facility.number_of_beds : 0,
		zip: facility ? facility.zip : 0,
		rooms: facility?.rooms?.map((room: Room) => ({
			name: room.name,
			id: room.id,
			licensed: room.licensed,
		})),
		schedule_record_types: facility?.schedule_record_types?.map((r) => {
			return {
				name: r.name,
				target: r.target,
			};
		}),
	};
	const is_artificial = facility?.name.includes('*');

	// mutations for sending data to the API
	const [setFacilityConfig] = useSetFacilityConfigMutation();
	const [addFacility] = useAddFacilityMutation();

	// local state needed for this component
	const [selectedIntraopClone, setSelectedIntraopClone] = useState<{ label: string; value: string } | null>();
	const [selectedScheduledClone, setSelectedScheduledClone] = useState<{ label: string; value: string } | null>();
	const [distinctRooms, setDistinctRooms] = useState('');
	const [buttonLoading, setButtonLoading] = useState(false);

	const [schedule_record_types, setSchedule_record_types] = useState<RecordType[]>(defaults.schedule_record_types ?? []);

	// get the possbile clone options
	const intraopCloneOptions = otherFacilities
		.filter((f) => !f.name.includes('*'))
		.map((otherFacility) => ({
			label: getFacilityName(otherFacility),
			value: otherFacility.id.toString(),
		}));

	// we can only clone facilites that have scheduled data
	const scheduleCloneOptions = otherFacilities
		.filter((f) => !f.name.includes('*'))
		.filter(
			(f) =>
				schedulingDataRequiredFields.filter((i) => Object.keys(f.field_mapping).includes(i)).length ===
				schedulingDataRequiredFields.length
		)
		.map((otherFacility) => ({
			label: getFacilityName(otherFacility),
			value: otherFacility.id.toString(),
		}));

	// used for artificial facilities
	const [deleteFacility] = useDeleteArtificialFacilityMutation();

	// form state
	const {
		handleSubmit,
		register,
		reset,
		setValue,
		control,
		watch,
		getValues,
		setError,
		formState: { errors, isDirty, dirtyFields },
	} = useForm<UpdateFacilitySettingsFormInputs>({
		resolver: yupResolver(updateFacilitySettingsFormSchema),
		defaultValues: defaults,
		mode: 'onChange',
	});

	// check for artificial facilities
	const { data: selectedFacilities } = useGetChildFacilitiesQuery(
		{
			facility_id: facility?.id,
		},
		{
			skip: !is_artificial,
		}
	);

	// check if filters needs to be reset, if rooms or license_type changed
	const reset_filters =
		Object.keys(dirtyFields).filter((element) => element === 'license_type' || element === 'rooms').length > 0;

	// rooms handling
	const {
		fields: rooms,
		append: appendRoom,
		remove: removeRoom,
		update: updateRoom,
	} = useFieldArray({ name: 'rooms', control });

	const [isLicenceAll, setIsLicenceAll] = useState(rooms.every((d) => d.licensed));
	const roomsValues = getValues('rooms') ?? [];

	// function that resets the form state
	const resetAddForm = useCallback(() => {
		reset();
		setSelectedIntraopClone(null);
		setSelectedScheduledClone(null);
		setValue(`license_type`, createLicenseBits(FacilityLicense.core));
	}, [reset, setSelectedIntraopClone, setValue, setSelectedScheduledClone]);

	// function that resets the form state on getting fresh data from the API
	const mergeNewFacilityData = useCallback(
		(fac: Facility) => {
			setSelectedIntraopClone(null);
			setSelectedScheduledClone(null);
			reset(convertFacilityToFormBody(fac));
		},
		[reset, setSelectedIntraopClone, setSelectedScheduledClone]
	);

	// called when creating a new facility, as the facility goes from undefined -> truthy
	// this also populates the new "default" values in the form, as they are the baseline from the API
	useEffect(() => {
		if (facility) {
			mergeNewFacilityData(facility);
		} else {
			resetAddForm();
			setValue(`license_type`, createLicenseBits(FacilityLicense.core), { shouldDirty: true });
		}
	}, [facility, otherFacilities, mergeNewFacilityData, resetAddForm, setValue]);

	// get distinct rooms from field value, first by getting the rooms on each line, and then by removing duplicates
	const distinctRoomExtractor = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const allRooms: string[] = [];
		const lines = Array.from(event.currentTarget.value.split(/\r?\n/));
		lines.forEach((line) => {
			const rooms = line.split(',');
			rooms.forEach((r, i) => {
				const trimmed = r.trim();
				if (trimmed) {
					allRooms.push(trimmed);
				}
			});
		});
		setDistinctRooms(Array.from(new Set(allRooms)).join(', '));
	};

	// adds rooms from widget to fields
	const bulkAddRooms = () => {
		const emptyRooms: number[] = [];
		const customRooms = getValues('rooms');
		customRooms &&
			customRooms.forEach((element, i) => {
				if (element.name.trim() === '') {
					emptyRooms.push(i);
				}
			});
		removeRoom(emptyRooms);
		const possibleNewRooms = distinctRooms.split(',').map((s) => s.trim());
		const knownRoomNames = rooms.map((field) => field.name);

		let totalRooms = 0;
		possibleNewRooms.forEach((roomName) => {
			if (!knownRoomNames.includes(roomName)) {
				appendRoom({ name: roomName.trim(), licensed: false });
				totalRooms++;
			}
		});

		if (totalRooms > 0) {
			createToast({
				title: `${totalRooms} rooms were added.`,
				variant: 'success',
			});
		}

		setDistinctRooms('');
	};
	// submit forms
	const onSubmit = async (formData: UpdateFacilitySettingsFormInputs) => {
		if (!selectedSystem) return;

		////////////////////// checking schedule data //////////////////////
		// all keys for schedule subset mappings, they all contain schedule$
		const schedule_keys = Object.keys(formData.field_mappings).filter((k) => k.includes('schedule$'));

		// check hasSchedulingData fields
		const schedule_fields = schedulingDataRequiredFields.map((field) => ({
			path: `field_mappings.${field}`,
			value: formData.field_mappings[field as keyof typeof formData.field_mappings],
		}));

		// if scheduleing data is present, either provider _scheduled_procedure_stop or _scheduled_duration
		if (hasSchedulingData && !selectedScheduledClone) {
			if (!(formData.field_mappings.schedule$_procedure_stop || formData.field_mappings.schedule$_duration)) {
				setError('field_mappings.schedule$_procedure_stop', {
					type: 'manual',
					message: 'Must be provided if no scheduled duration',
				});
				setError('field_mappings.schedule$_duration', {
					type: 'manual',
					message: 'Must be provided if no scheduled procedure stop',
				});
				return;
			}

			for (let i = 0; i < schedule_fields.length; i += 1) {
				if (!schedule_fields[i].value) {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					setError(schedule_fields[i].path as any, {
						type: 'manual',
						message: 'This field is required',
					});
					return;
				}
			}
		}
		////////////////////// finished checking schedule data /////////////

		setButtonLoading(true);

		// parse rooms to make them payload friendly
		const rooms =
			formData?.rooms?.map((r) => {
				if (r.id) {
					return {
						id: r.id,
						name: r.name,
						licensed: r.licensed,
					};
				} else {
					return {
						name: r.name,
						licensed: r.licensed,
					};
				}
			}) ?? [];

		// build initial payload
		let payload: SetFacilityConfigurationRequest | CreateFacilityRequest;
		if (editing && formData.facility_id) {
			payload = {
				reset_filters: reset_filters,
				facility_id: formData.facility_id,
				healthsystem_id: providedSystem?.id ?? selectedSystem,
				name: formData.name,
				street_address: formData.street_address,
				city: formData.city,
				state: formData.state,
				zip: formData.zip,
				ehr: formData.ehr,
				cms_designation: formData.cms_designation,
				number_of_beds: formData.number_of_beds,
				display_name: formData.intraop_facility_name_alias ?? '',
				license_type: formData.license_type,
				field_mappings: {
					...formData.field_mappings,
				},
				rooms: rooms,
				schedule_record_types: hasSchedulingData ? schedule_record_types : undefined,
			};
		} else {
			payload = {
				healthsystem_id: providedSystem?.id ?? selectedSystem,
				name: formData.name,
				street_address: formData.street_address,
				city: formData.city,
				state: formData.state,
				zip: formData.zip,
				ehr: formData.ehr,
				cms_designation: formData.cms_designation,
				number_of_beds: formData.number_of_beds,
				display_name: formData.intraop_facility_name_alias ?? '',
				license_type: formData.license_type,
				field_mappings: {
					...formData.field_mappings,
				},
				rooms: rooms,
				schedule_record_types: hasSchedulingData ? schedule_record_types : undefined,
			};
		}

		// if clone, copy field mappings
		if (formData?.clone_facility_id) {
			const source_mappings = otherFacilities.find((f) => f.id === formData.clone_facility_id)?.field_mapping;
			payload.field_mappings = source_mappings ?? {};
		}

		// if user unchecked hasSchedulingData, empty previous state
		if (!hasSchedulingData) {
			const temp_map = { ...payload.field_mappings };

			for (let i = 0; i < schedule_keys.length; i += 1) {
				temp_map[schedule_keys[i] as keyof typeof payload.field_mappings] = undefined;
			}

			payload.field_mappings = temp_map;
		}

		// clone schedule config subset from other facility
		if (selectedScheduledClone) {
			const source_mappings = otherFacilities.find((f) => f.id === parseInt(selectedScheduledClone.value))?.field_mapping;
			const temp_map = { ...payload.field_mappings };
			const source_record_types = otherFacilities.find(
				(f) => f.id === parseInt(selectedScheduledClone.value)
			)?.schedule_record_types;

			for (let i = 0; i < schedule_keys.length; i += 1) {
				temp_map[schedule_keys[i] as keyof typeof payload.field_mappings] =
					source_mappings?.[schedule_keys[i] as keyof typeof payload.field_mappings];
			}

			payload.field_mappings = temp_map;
			payload.schedule_record_types = source_record_types;
		}

		// submit request
		if (editing && formData.facility_id) {
			const response = await setFacilityConfig(payload as SetFacilityConfigurationRequest);
			handleApiResponse(response, {
				success: (data) => {
					createToast({
						title: `The facility was successfully updated.`,
						variant: 'success',
					});
					// update the new "default" state of the form with current state in the API
					mergeNewFacilityData(data.facility);
				},
				error: (error) => {
					createToast({
						title: getQueryError(error),
						variant: 'error',
					});
				},
			});
		} else {
			const response = await addFacility(payload);
			handleApiResponse(response, {
				success: () => {
					createToast({
						title: `The facility was created.`,
						variant: 'success',
					});
					// empties the "add" form so that user can continue adding
					resetAddForm();
				},
				error: (error) => {
					createToast({
						title: getQueryError(error),
						variant: 'error',
					});
				},
			});
		}
		setButtonLoading(false);
	};

	const deleteArtificalFacility = async () => {
		setButtonLoading(true);
		const response = await deleteFacility({
			facility_id: facility?.id,
			refresh_cache: true,
		});
		handleApiResponse(response, {
			success: () => {
				createToast({
					title: `The combined facility was deleted.`,
					variant: 'success',
				});
				setButtonLoading(false);
			},
			error: (error) => {
				createToast({
					title: getQueryError(error),
					variant: 'error',
				});
				setButtonLoading(false);
			},
		});
	};

	const fieldItems = Object.entries(fieldMappingData);

	// this is used to disable or enable the department within facility toggle
	useEffect(() => {
		watch('street_address'); // used to keep UI updated based on toggle changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const same_address_as_healthsystem =
		(providedSystem?.street_address ?? null) === (getValues('street_address') ?? [null])[0];

	useEffect(() => {
		if (same_address_as_healthsystem) {
			setShowGeo(true);
		}
	}, [same_address_as_healthsystem]);

	return (
		<Accordion type='multiple' itemStyle={is_artificial ? 'dark' : 'contained'} className='mb-2 bg-white'>
			<Accordion.Item value={defaults.name.length > 0 ? defaults.name : '+ Add new facility'}>
				<form onSubmit={handleSubmit(onSubmit)} autoComplete='off' noValidate>
					<section>
						<p className='font-semibold text-h6 pb-5 uppercase'>Facility Naming Convention</p>

						<div className='grid lg:grid-cols-2 lg:w-3/4 gap-x-14 sm:grid-cols-1 sm:max-w-max'>
							<div>
								<TextField
									label='Client Intraop Name'
									disabled={is_artificial}
									errorMessage={errors?.name?.message}
									{...register(`name`)}
								/>
								<p className='text-p3 text-gray-400 py-1 italic'>
									This is the name of the facility exactly as it appears in the client intraop
								</p>
							</div>
							<div>
								{!is_artificial ? (
									<>
										<TextField
											label='Display Name'
											disabled={is_artificial}
											defaultValue={facility?.intraop_facility_name_alias}
											errorMessage={errors?.intraop_facility_name_alias?.message}
											{...register(`intraop_facility_name_alias`)}
										/>
										<p className='text-p3 text-gray-400 py-1 italic'>
											This is how the facility name will be displayed in Merlin (optional)
										</p>
									</>
								) : (
									<>
										<p className='pl-5 text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary'>Child Facilities</p>
										<div className='pl-5 flex flex-wrap overflow-y-auto w-96 max-h-32'>
											{selectedFacilities?.names.map((facility, i) => (
												<p className='px-3 py-2 bg-blue-100 text-bluegray-600 h-fit w-fit m-1 rounded-sm whitespace-nowrap' key={i}>
													{facility.label}
												</p>
											))}
										</div>
									</>
								)}
							</div>
						</div>
					</section>

					<section className='py-3'>
						<div className='grid lg:grid-cols-2 w-2/3 gap-x-14 sm:grid-cols-1 mt-3'>
							<ToggleGroup
								label='Is this Facility a department within a larger facility?'
								className='w-28'
								options={[
									{
										label: 'Yes',
										value: 'yes',
									},
									{
										label: 'No',
										value: 'no',
									},
								]}
								onValueChange={
									// TOGGLE SHOWGEO
									(value) => {
										if (value === 'yes') {
											setShowGeo(true);

											// set all geo fields to default values from providedsystem
											setValue('street_address', providedSystem?.street_address || '');
											setValue('city', providedSystem?.city || '');
											setValue('state', providedSystem?.state || '');
											setValue('zip', providedSystem?.zip || 0);

											// set cms, ehr, beds
											setValue('cms_designation', providedSystem?.cms_designation || '');
											setValue('ehr', providedSystem?.ehr || '');
											setValue('number_of_beds', providedSystem?.number_of_beds || 0, { shouldDirty: true });
										} else if (value === 'no') {
											setShowGeo(false);
										}
									}
								}
								defaultValue={same_address_as_healthsystem ? 'yes' : 'no'}
								disabled={!!facility?.street_address && !!getValues('street_address')}
							/>
						</div>
					</section>
					{!showGeo && (
						<>
							<section className='py-3'>
								<p className='font-semibold text-h6 py-5 pb-2 uppercase mb-3'>Geographic Information</p>

								<div className='flex gap-4 flex-wrap rounded-md w-4/6'>
									<TextField
										label='Street Address'
										defaultValue={facility?.street_address ?? undefined}
										errorMessage={errors.street_address?.message}
										{...register('street_address')}
										sizeX='lg'
									/>
									<TextField
										label='City'
										defaultValue={facility?.city ?? undefined}
										errorMessage={errors.city?.message}
										{...register('city')}
										sizeX='lg'
									/>
									<Select
										label='State'
										sizeY='sm'
										sizeX='sm'
										defaultValue={{ label: facility?.state, value: facility?.state }}
										errorMessage={errors.state?.message}
										options={us_states.map((state) => ({ label: state, value: state }))}
										onChange={(option) => setValue('state', option?.label ?? '')}
									/>

									<TextField
										type='number'
										label='Zip Code'
										defaultValue={facility?.zip ?? undefined}
										errorMessage={errors.zip?.message}
										{...register('zip')}
										sizeX='sm'
									/>
								</div>
							</section>
							<section className='py-3'>
								<p className='font-semibold text-h6 py-5 pb-2 uppercase mb-3'>Other Information</p>

								<div className='flex gap-2 rounded-md'>
									<div className='flex flex-col w-1/3'>
										<p className='mb-1 font-semibold text-[1em] text-gray-600'>EHR</p>
										<p className='text-[0.75em] text-gray-500 mb-3'>
											An electronic health record (EHR) is a digital version of a patient&apos;s medical history that is stored on
											a computer and maintained by their provider.
										</p>
										<Select
											label='EHR'
											sizeY='sm'
											sizeX='lg'
											defaultValue={{ label: facility?.ehr, value: facility?.ehr }}
											errorMessage={errors.ehr?.message}
											options={ehr_systems.map((system) => ({ label: system, value: system }))}
											onChange={(option) => setValue('ehr', option?.label ?? '')}
										/>
									</div>
									<div className='flex flex-col w-1/3'>
										<p className='mb-1 font-semibold text-[1em] text-gray-600'>CMS Designation</p>
										<p className='text-[0.75em] text-gray-500 mb-3'>
											CMS is required for healthcare organizations that want to participate in and receive payment from Medicare or
											Medicaid programs.
										</p>
										<Select
											label='Designation'
											sizeY='sm'
											defaultValue={{ label: facility?.cms_designation, value: facility?.cms_designation }}
											sizeX='lg'
											errorMessage={errors.cms_designation?.message}
											options={cms_designations.map((cms) => ({ label: cms, value: cms }))}
											onChange={(option) => setValue('cms_designation', option?.label ?? '')}
										/>
									</div>
									<div className='flex flex-col w-1/3'>
										<p className='mb-1 font-semibold text-[1em] text-gray-600'>Number of Beds</p>
										<p className='text-[0.75em] text-gray-500 mb-3'>
											The number of beds in a hospital is the number of beds that are regularly maintained and staffed to provide
											care for inpatients.
										</p>
										<TextField
											type='number'
											defaultValue={facility?.number_of_beds ?? undefined}
											label='Beds'
											errorMessage={errors.number_of_beds?.message}
											{...register('number_of_beds')}
											sizeX='lg'
											sizeY='sm'
										/>
									</div>
								</div>
							</section>
						</>
					)}

					<section className='py-3'>
						<p className='font-semibold text-h6 py-5 pb-2 uppercase'>License Type</p>

						<div className='grid lg:grid-cols-2 w-2/3 gap-x-14 sm:grid-cols-1'>
							<p className='text-p3 text-gray-400 py-1 italic'>
								You can assign one license type per facility. Use this button to modify the license type if required.
							</p>
							<LicenseToggleGroup
								defaultValue={facility?.license ?? ''}
								onValueChange={(e: string) => {
									//reset();
									setValue(`license_type`, e, { shouldDirty: true });
								}}
								label='License'
								hideLabel
								className='w-[30em]'
								options={[
									{
										label: 'Core',
										value: createLicenseBits(FacilityLicense.core),
										license_enum: FacilityLicense.core,
									},
									{
										label: 'Block',
										value: createLicenseBits(FacilityLicense.block),
										license_enum: FacilityLicense.block,
									},
									{
										label: 'Anesthesia',
										value: createLicenseBits(FacilityLicense.anesthesia),
										license_enum: FacilityLicense.anesthesia,
									},
									{
										label: 'Nurse Staffing',
										value: createLicenseBits(FacilityLicense.nurse_staffing),
										license_enum: FacilityLicense.nurse_staffing,
									},
								]}
								disabled={false}
							/>
						</div>
					</section>

					{!is_artificial && (
						<section>
							<p className='font-semibold text-h6 py-5 pb-4 uppercase'>Intraop Field Mapping</p>

							<div className='grid lg:grid-cols-2 w-2/3 gap-x-2 sm:grid-cols-1 sm:max-w-max'>
								<Select
									disabled={is_artificial}
									label='Clone the field mapping of another facility'
									options={intraopCloneOptions}
									value={selectedIntraopClone}
									onChange={(opt) => {
										if (opt) {
											const facilityId = parseInt(opt.value);
											setSelectedIntraopClone(opt);
											setValue('clone_facility_id', facilityId, { shouldDirty: true });
										} else {
											setValue('clone_facility_id', null);
											setSelectedIntraopClone(null);
										}
									}}
									reactSelectProps={{ isClearable: true }}
								/>
							</div>
						</section>
					)}

					{!selectedIntraopClone && !is_artificial && (
						<section className='border-blue-50 border-transparent border-b-0 border-2 mt-8'>
							<div className='grid grid-cols-12 border-b-2 border-blue-50 p-5'>
								<div className='col-span-5'>
									<p className='font-semibold text-h6 uppercase'>Client Intraop Field Name</p>
								</div>
								<div className='col-span-2'>
									<p className='font-semibold text-h6 uppercase'>Mapped Field Name</p>
								</div>
								<div className='col-span-4'>
									<p className='font-semibold text-h6 uppercase'>Description</p>
								</div>
								<div className='col-span-1'>
									<p className='font-semibold text-h6 uppercase text-right'>Required</p>
								</div>
							</div>

							{fieldItems
								.filter(([field, fieldSettings], i) => fieldSettings.type !== 'schedule')
								.map(([field, fieldSettings], i) => (
									<div
										key={i}
										className='grid grid-cols-12 gap-r-6 p-5 py-3 justify-center items-center border-b-2 border-blue-50'
									>
										<div className='col-span-5 w-11/12'>
											<TextField
												disabled={is_artificial}
												{...register(`field_mappings.${field as keyof FieldMapping}`)}
												errorMessage={errors.field_mappings?.[field as keyof FieldMapping]?.message}
												label={fieldSettings.label}
												hideLabel
											/>
										</div>
										<div className='col-span-2'>
											<p className='text-h6'>{fieldSettings.name}</p>
										</div>
										<div className='col-span-4'>
											<p className='text-p2 text-gray-400 italic'>{fieldSettings.label}</p>
										</div>
										<div className='col-span-1'>
											<p
												className={classNames('text-h6 uppercase text-right', {
													'font-semibold':
														fieldSettings.required ||
														(fieldSettings.license_tag && fieldSettings.license_tag === getValues('license_type').toString()),
												})}
											>
												{fieldSettings.required ||
												(fieldSettings.license_tag && fieldSettings.license_tag === getValues('license_type').toString()) === true
													? 'Yes'
													: 'No'}
											</p>
										</div>
									</div>
								))}
						</section>
					)}

					{!is_artificial && (
						<section className='py-3'>
							<p className='font-semibold text-h6 py-5 pb-2 uppercase'>Scheduling Data</p>

							<div className='grid lg:grid-cols-2 w-2/3 gap-x-14 sm:grid-cols-1'>
								<p className='text-p3 text-gray-400 py-1 italic'>
									Select `Yes` if a scheduling feed has been enabled for this facility
								</p>
								<ToggleGroup
									label=''
									options={[
										{ label: 'Has Scheduling Data', value: 'yes' },
										{ label: 'No Scheduling Data', value: 'no' },
									]}
									defaultValue={hasSchedulingData ? 'yes' : 'no'}
									onValueChange={(e: string) => {
										//reset();
										setScheduleToggleState(e === 'yes');
										setValue('field_mappings.schedule$_case_create_date', undefined, { shouldDirty: true });
									}}
								/>
							</div>
						</section>
					)}

					{!is_artificial && hasSchedulingData && (
						<section>
							<div className='grid lg:grid-cols-2 w-2/3 gap-x-2 sm:grid-cols-1 sm:max-w-max'>
								<Select
									disabled={is_artificial}
									label='Clone the schedule field mapping of another facility'
									options={scheduleCloneOptions}
									value={selectedScheduledClone}
									onChange={(opt) => {
										if (opt) {
											setSelectedScheduledClone(opt);
											setValue('field_mappings.schedule$_case_create_date', undefined, { shouldDirty: true }); // used to make form dirty, will be populated onSubmit
										} else {
											setSelectedScheduledClone(null);
										}
									}}
									reactSelectProps={{ isClearable: true }}
								/>
							</div>
						</section>
					)}

					{!selectedScheduledClone && !is_artificial && hasSchedulingData && (
						<>
							<section className='border-blue-50 border-transparent border-b-0 border-2 mt-8'>
								<div className='grid grid-cols-12 border-b-2 border-blue-50 p-5'>
									<div className='col-span-5'>
										<p className='font-semibold text-h6 uppercase'>Schedule Field Name</p>
									</div>
									<div className='col-span-2'>
										<p className='font-semibold text-h6 uppercase'>Mapped Field Name</p>
									</div>
									<div className='col-span-4'>
										<p className='font-semibold text-h6 uppercase'>Description</p>
									</div>
									<div className='col-span-1'>
										<p className='font-semibold text-h6 uppercase text-right'>Required</p>
									</div>
								</div>

								{fieldItems
									.filter(([field, fieldSettings], i) => fieldSettings.type === 'schedule')
									.map(([field, fieldSettings], i) => (
										<div
											key={i}
											className='grid grid-cols-12 gap-r-6 p-5 py-3 justify-center items-center border-b-2 border-blue-50'
										>
											<div className='col-span-5 w-11/12'>
												<TextField
													disabled={is_artificial}
													{...register(`field_mappings.${field as keyof FieldMapping}`)}
													errorMessage={errors.field_mappings?.[field as keyof FieldMapping]?.message}
													label={fieldSettings.label}
													hideLabel
												/>
											</div>
											<div className='col-span-2'>
												<p className='text-h6'>{fieldSettings.name}</p>
											</div>
											<div className='col-span-4'>
												<p className='text-p2 text-gray-400 italic'>{fieldSettings.label}</p>
											</div>
											<div className='col-span-1'>
												<p
													className={classNames('text-h6 uppercase text-right', {
														'font-semibold': fieldSettings.required_message ? fieldSettings.required_message === 'Yes' : false,
													})}
												>
													{fieldSettings.required || (fieldSettings.required_message ? fieldSettings.required_message : 'No')}
												</p>
											</div>
										</div>
									))}
							</section>
							<div className='flex flex-col my-8'>
								<div className='flex flex-col mb-2'>
									<p className='font-semibold text-h6 pb-2 uppercase'>Record Types</p>

									<p className='text-p3 text-gray-400 leading-6 italic'>
										Record Type is a label or category that describes the status of a scheduled case or an action that has been
										performed on a scheduled case.
									</p>
								</div>
								<section className='border-blue-50 border-transparent border-b-0 border-2'>
									<div className='grid grid-cols-12 border-b-2 border-blue-50 p-5'>
										<div className='col-span-5'>
											<p className='font-semibold text-h6 uppercase'>Record Type Name</p>
										</div>
										<div className='col-span-2'>
											<p className='font-semibold text-h6 uppercase'>Mapped Field Name</p>
										</div>
										<div className='col-span-4'>
											<p className='font-semibold text-h6 uppercase'>Description</p>
										</div>
										<div className='col-span-1'>
											<p className='font-semibold text-h6 uppercase text-right'>Required</p>
										</div>
									</div>

									{scheduleRecordTypes.map((recordType, i) => (
										<div
											key={i}
											className='grid grid-cols-12 gap-r-6 p-5 py-3 justify-center items-center border-b-2 border-blue-50'
										>
											<div className='col-span-5 w-11/12'>
												<TextField
													label={''}
													sizeX='lg'
													placeholder='RecordA, RecordB, RecordC...'
													value={schedule_record_types
														.filter((rt) => rt.target === recordType.name)
														.map((rt) => rt.name)
														.join(', ')}
													onChange={(text) => {
														// either update or create
														const values = `${text.target.value}`.split(',').map((rt) => rt.trimStart());

														values.forEach((value, i) => {
															// only keep values that are in values array and not already part of another target
															setSchedule_record_types((prevState) => {
																if (prevState.find((recordType) => recordType.name === value)) {
																	return prevState;
																} else {
																	const new_arr = [...prevState, { name: value, target: recordType.name }].filter(
																		(recordTypeInner) =>
																			values.includes(recordTypeInner.name) || recordTypeInner.target !== recordType.name
																	);

																	setValue('schedule_record_types', new_arr, { shouldDirty: true });

																	return new_arr;
																}
															});
														});
													}}
												/>
											</div>
											<div className='col-span-2'>
												<p className='text-h6'>{recordType.name}</p>
											</div>
											<div className='col-span-4'>
												<p className='text-p2 text-gray-400 italic'>{recordType.description}</p>
											</div>
											<div className='col-span-1'>
												<p className={classNames('text-h6 uppercase text-right font-semibold')}>Yes</p>
											</div>
										</div>
									))}
								</section>
							</div>
						</>
					)}

					{!is_artificial && (
						<>
							<section>
								<p className='font-semibold text-h6 py-7 uppercase'>Room &amp; License Information</p>

								<div className='grid lg:grid-cols-12 gap-10 sm:grid-cols-1'>
									<div className='lg:col-span-4 sm:col-span-full'>
										<p className='font-semibold text-h6 pb-2 uppercase'>Room Bulk Entry</p>

										<p className='text-p3 text-gray-400 leading-6 italic'>
											Use this tool to quickly add many rooms to the table below. Simply copy all of the room names from the
											intraop file and paste into the{' '}
											<span className='inline-block uppercase bg-blue-50 px-1 rounded text-gray-400'>TEXT INPUT</span> box. A list
											of the distinct rooms should then be automatically populated in the other field (on the right) labeled{' '}
											<span className='inline-block uppercase bg-blue-50 px-1 rounded text-gray-400'>DISTINCT ROOMS OUTPUT</span>.
											If the list of distinct rooms looks correct, you can click the button that reads{' '}
											<span className='inline-block uppercase bg-blue-50 px-1 rounded text-gray-400'>Auto Add Rooms</span> to
											populate the table with the values. Any rooms already in the table will NOT be added twice.
										</p>
									</div>

									<div className='lg:col-span-4 sm:col-span-full'>
										<TextArea
											label='Text Input'
											disabled={is_artificial}
											placeholder='Paste the room names here...'
											onChange={distinctRoomExtractor}
										/>
									</div>

									<div className='lg:col-span-4 sm:col-span-full'>
										<TextArea
											className='cursor-not-allowed'
											value={distinctRooms}
											label='Distinct Rooms Output'
											placeholder='OR01, OR02, OR03, Cath, ENDO'
											readOnly
										/>
										<div className='flex justify-end p-4 pr-0 pt-2'>
											<Button
												variant='primary-ghost'
												sizeX='md'
												type='button'
												disabled={!distinctRooms || is_artificial}
												onClick={() => {
													bulkAddRooms();
												}}
											>
												<span className='material-symbol'>playlist_add</span>
												Auto Add Rooms
											</Button>
										</div>
									</div>
								</div>
							</section>
							<section className='mt-8 mb-4'>
								<p className='font-semibold text-h6 pb-2 uppercase'>Room Names</p>

								<div>
									<p className='text-p3 text-gray-400 italic'>
										List the room names exactly as they appear in the client intraop file or in the{' '}
										<span className='inline-block uppercase bg-blue-50 p-1 rounded text-gray-400'>Distinct Rooms Output</span>{' '}
										field above.
									</p>
								</div>
							</section>
							<section className='border-blue-50 border-transparent border-2 p-4'>
								{rooms.length > 1 && (
									<div className='grid sm:grid-cols-2 lg:grid-cols-12 my-1 gap-4 justify-end mb-4'>
										<div className='sm:col-start-1 lg:col-start-10 col-span-2'>
											<ToggleButton
												text={isLicenceAll ? 'Unlicense All' : 'License All'}
												toggle={(isActive) => {
													rooms?.forEach(function (f, i) {
														updateRoom(i, { ...f, id: defaults?.rooms?.at(i)?.id, licensed: isActive });
													});
													setIsLicenceAll(isActive);
												}}
												isActive={isLicenceAll}
											/>
										</div>
									</div>
								)}
								{rooms?.map((field, i) => (
									<div key={field.id} className='grid grid-cols-12 my-1 gap-4 justify-center'>
										<div className='lg:col-span-9 sm:col-span-full'>
											<TextField
												disabled={is_artificial}
												label=''
												hideLabel
												placeholder='+ Add Room'
												errorMessage={errors?.rooms?.[i]?.name?.message}
												{...register(`rooms.${i}.name` as const)}
											/>
										</div>

										<div className='lg:col-span-2 sm:col-span-full'>
											<ToggleGroup
												onValueChange={(e) => {
													setValue(`rooms.${i}.licensed` as const, Boolean(parseInt(e)), { shouldDirty: true });
													setIsLicenceAll(roomsValues?.every((d) => d.licensed));
												}}
												disabled={is_artificial}
												label=''
												hideLabel
												className='w-full'
												defaultValue={Number(field.licensed).toString()}
												options={[
													{
														label: 'Licensed',
														value: '1',
													},
													{
														label: 'Unlicensed',
														value: '0',
													},
												]}
											/>
										</div>

										<div className='lg:col-span-1 sm:col-span-full'>
											{!editing && (
												<Button
													variant='secondary-ghost'
													disabled={is_artificial}
													onClick={() => {
														removeRoom(i);
													}}
												>
													<span className='material-symbol block'>delete</span>
												</Button>
											)}
										</div>
									</div>
								))}

								<div className='mt-2'>
									<ButtonLink
										onClick={() => {
											appendRoom({ name: '', licensed: true, id: null });
										}}
									>
										+ Add Room
									</ButtonLink>
								</div>
							</section>
						</>
					)}

					<div className='flex justify-end pt-8'>
						{is_artificial &&
							(buttonLoading ? (
								<div className='h-4 w-4 pr-12'>
									<LoadingIndicator />
								</div>
							) : (
								<p
									className='text-blue-500 text-p2 cursor-pointer underline pr-5 whitespace-nowrap self-center'
									onClick={() => {
										deleteArtificalFacility();
									}}
								>
									Delete
								</p>
							))}
						{reset_filters && !(getValues('license_type').toString() === '-1' && isDirty) && (
							<div className='bg-yellow-100 flex p-2 rounded-md justify-between items-center mr-4 pr-4'>
								<span className='material-symbols-outlined text-yellow-500 pr-2'>warning</span>
								<p className='text-p3 text-gray-500'>
									Saving a new facility configuration will result in all filters for all users in this facility to be restored to
									their default settings.
								</p>
							</div>
						)}
						{!is_artificial && (
							<Button
								variant='primary'
								sizeX='md'
								sizeY='md'
								disabled={!isDirty || is_artificial || (getValues('license_type').toString() === '-1' && isDirty)}
								isWorking={buttonLoading}
								type='submit'
							>
								Save
							</Button>
						)}
					</div>
				</form>
			</Accordion.Item>
		</Accordion>
	);
}

/**
 * A utility for the component to quickly create a form body from a facility
 * @param fac facility to transform to a form body
 * @returns fields with default values for the form in this component
 */
function convertFacilityToFormBody(fac: Facility): UpdateFacilitySettingsFormInputs {
	return {
		facility_id: fac.id,
		name: fac.name,
		street_address: fac.street_address,
		city: fac.city,
		state: fac.state,
		zip: fac.zip,
		ehr: fac.ehr,
		cms_designation: fac.cms_designation,
		number_of_beds: fac.number_of_beds,
		intraop_facility_name_alias: fac.intraop_facility_name_alias,
		license_type: fac.license,
		clone_facility_id: null,
		field_mappings: {
			client_provided_case_id: fac.field_mapping.client_provided_case_id,
			patient_in_room: fac.field_mapping.patient_in_room,
			patient_out_room: fac.field_mapping.patient_out_room,
			room: fac.field_mapping.room,
			surgeon: fac.field_mapping.surgeon,
			facility: fac.field_mapping.facility,
			surgery_date: fac.field_mapping.surgery_date,
			scheduled_start: fac.field_mapping.scheduled_start,
			procedure_start: fac.field_mapping.procedure_start,
			procedure_stop: fac.field_mapping.procedure_stop,
			anesthesia_start: fac.field_mapping.anesthesia_start,
			anesthesia_end: fac.field_mapping.anesthesia_end,
			primary_anesthesiologist: fac.field_mapping.primary_anesthesiologist,
			primary_anesthetist: fac.field_mapping.primary_anesthetist,
			asa_class: fac.field_mapping.asa_class,
			anesthesia_ready: fac.field_mapping.anesthesia_ready,
			procedure_description: fac.field_mapping.procedure_description,
			encounter_type: fac.field_mapping.encounter_type,
			service_line: fac.field_mapping.service_line,
			is_add_on: fac.field_mapping.is_add_on,
			case_create_date: fac.field_mapping.case_create_date,
			case_cancel_date: fac.field_mapping.case_cancel_date,
			scheduled_stop: fac.field_mapping.scheduled_stop,
			delay_code: fac.field_mapping.delay_code,
			delay_type: fac.field_mapping.delay_type,
			delay_reason: fac.field_mapping.delay_reason,
			delay_duration: fac.field_mapping.delay_duration,
			scheduled_room: fac.field_mapping.scheduled_room,
			cpt_code: fac.field_mapping.cpt_code,
			cpt_description: fac.field_mapping.cpt_description,
			primary_payor: fac.field_mapping.primary_payor,
			primary_circulator: fac.field_mapping.primary_circulator,
			primary_cst: fac.field_mapping.primary_cst,
			primary_first_assist: fac.field_mapping.primary_first_assist,
			preop_in: fac.field_mapping.preop_in,
			pacu_p1_in: fac.field_mapping.pacu_p1_in,
			pacu_p1_out: fac.field_mapping.pacu_p1_out,
			pacu_p2_in: fac.field_mapping.pacu_p2_in,
			pacu_p2_out: fac.field_mapping.pacu_p2_out,
			schedule$_case_id: fac.field_mapping.schedule$_case_id,
			schedule$_line_number: fac.field_mapping.schedule$_line_number,
			schedule$_record_type: fac.field_mapping.schedule$_record_type,
			schedule$_cancel_date: fac.field_mapping.schedule$_cancel_date,
			schedule$_service_line: fac.field_mapping.schedule$_service_line,
			schedule$_case_create_date: fac.field_mapping.schedule$_case_create_date,
			schedule$_surgery_date: fac.field_mapping.schedule$_surgery_date,
			schedule$_procedure_start: fac.field_mapping.schedule$_procedure_start,
			schedule$_procedure_stop: fac.field_mapping.schedule$_procedure_stop,
			schedule$_duration: fac.field_mapping.schedule$_duration,
			schedule$_primary_surgeon: fac.field_mapping.schedule$_primary_surgeon,
			schedule$_facility: fac.field_mapping.schedule$_facility,
			schedule$_room: fac.field_mapping.schedule$_room,
			schedule$_procedure_description: fac.field_mapping.schedule$_procedure_description,
		},
		rooms: fac.rooms.map((r) => ({
			id: r.id,
			name: r.name,
			licensed: r.licensed,
		})),
		schedule_record_types: fac.schedule_record_types?.map((r) => {
			return {
				name: r.name,
				target: r.target,
			};
		}),
	};
}
