import { ApiEndpoints } from 'models/api';
import { standardApi } from 'store/api';

export interface DataExportResponse {
	download_link: string;
}
export interface DataExportRequest {
	file_name_prefix: string;
	facility_ids: number[];
	filters: {
		start_date: string;
		end_date: string;
	};
}
/**
 * Provides endpoints for retrieving and setting block settings
 */
export const dataExportApi = standardApi.injectEndpoints({
	endpoints: (build) => ({
		getDataExport: build.mutation<DataExportResponse, DataExportRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_DATA_EXPORT,
				method: 'POST',
				body,
			}),
		}),
	}),
});
export const { useGetDataExportMutation } = dataExportApi;
export const {
	endpoints: { getDataExport },
} = dataExportApi;
