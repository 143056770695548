import { ApiEndpoints } from 'models/api';
import { apiWithCore } from 'store/api';

export interface overviewItem {
	metric: string;
	surgeon: string;
}
export interface dowItem {
	day_of_week: number;
	volume: number;
}

export interface TopProcedureItem {
	name: string;
	volume: number;
	avg: number;
	wheels_in_avg: number;
	wheels_out_avg: number;
	procedure_time_avg: number;
}

export interface VolumeItem {
	dow: dowItem[];
	top5: TopProcedureItem[];
}

export interface FcotsRate {
	number_of_first_cases_on_time: number;
	number_of_first_cases: number;
	avg_fcots_delay: number;
}

export interface AverageTot {
	number_of_turnovers: number;
	number_turnovers_on_target: number;
	flip_rate: number;
	avg_case_vol: number;
	close_cut_turnover: number;
}

export interface SurgeonTableData {
	month_name: string;
	surgeon_name: string;
	overview: overviewItem[];
	case_volume: VolumeItem;
	fcots_rate: FcotsRate;
	average_tot: AverageTot;
	addon_rate: VolumeItem;
	volume_graph: number[];
}

export interface surgeonScorecardRequest {
	facility_id: number | null;
	surgeon_id: number[];
	start_date: string;
	end_date: string;
	fcots_grace: number;
	procedures: string[];
	tot_threshold: number;
}

/**
 * Provides endpoints for data values in database
 */
export const surgeonScorcardService = apiWithCore.injectEndpoints({
	endpoints: (build) => ({
		surgeonScorecard: build.query<{ surgeonData: SurgeonTableData[] }, surgeonScorecardRequest>({
			query: (body: surgeonScorecardRequest) => ({
				url: ApiEndpoints.SURGEON_SCORECARD,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
	}),
});

export const { useSurgeonScorecardQuery } = surgeonScorcardService;

export const {
	endpoints: { surgeonScorecard },
} = surgeonScorcardService;
