import type { ChartLegendOption } from 'components';

/**
 * Global Definitions
 * Since we don't currently have an elegant way to retrieve tailwind-generated colors in our TS code, we
 * have to define the colors here and try to reference them consistently from charting components.
 */
export const fontFamily = 'Inter, system-ui, sans-serif';
export const fontFamilySecondary = 'Barlow, system-ui, sans-serif';
export const border = 'hsl(212 40% 80% / .5)';

/** This style object can effectively hide an Axis that is displayed by default. */
export const hiddenAxisStyles = {
	axis: { stroke: 'transparent' },
	ticks: { stroke: 'transparent' },
	tickLabels: { fill: 'transparent' },
};

/** All of the color options for our charts. */
export type ChartColor = 'green' | 'red' | 'blue' | 'light-blue' | 'yellow' | 'dark-yellow';

/**
 * Utility function to get correct color for gauge chart.
 * Useful reference below:
 * https://surgicaldirections.atlassian.net/wiki/spaces/MERIWETHER/pages/161972228/Metric+Color+Ranges
 * @param type color code to retrieve
 * @returns string
 */
export function getColor(type: ChartColor | undefined) {
	switch (type) {
		case 'green':
			return 'hsl(157 59% 53%)'; // bg-green-500
		case 'red':
			return 'hsl(0 86% 59%)'; // bg-red-500
		case 'blue':
			return 'hsl(215 61% 36%)'; // bg-blue-700
		case 'yellow':
			return 'hsl(34 100% 81%)'; // bg-yellow-200
		case 'dark-yellow':
			return '#ffa42e'; // bg-yellow-600
		default:
			return 'hsl(215 93% 80%)'; // bg-bluegray-400
	}
}

/**
 * Room Timeline Definitions
 */

/** Flattened version of the useGetRoomTurnoverQuery, to easily export to csv file. */
export interface FlattenedTimelineData {
	event_type: string;
	x: string;
	y0: number;
	y: number;
	room: string;
	surgeon: string;
	time: string;
}

/** Items shown in the legend for room timeline page. */
export const timelineChartLegendOptions: ChartLegendOption[] = [
	{
		label: 'none',
		type: 'none',
		color: 'transparent',
	},
	{
		label: 'FCOTS Delay',
		type: 'fcots_delay',
		color: 'hsl(0 86% 59% / 1)', // equivalent of .bg-red-500
	},
	{
		label: 'Elective Case',
		type: 'case',
		color: 'hsl(215 91% 51% / 1)', // equivalent of .bg-blue-500
	},
	{
		label: 'Scheduled',
		type: 'case_schedule',
		color: '#9c88ff', // light purple
	},
	{
		label: 'Add-On',
		type: 'case_addon',
		color: 'hsl(215 90% 83% / 1)', // equivalent of .bg-blue-300
	},
	{
		label: 'Turnover Under Target',
		type: 'turnover',
		color: 'hsl(157 59% 53% / 1)', // equivalent of .bg-green-500
	},
	{
		label: 'Turnover Warning',
		type: 'turnover_warning',
		color: 'hsl(34 100% 59% / 1)', // equivalent of .bg-yellow-500
	},
	{
		label: 'Wheels In to Start',
		type: 'wheels_in',
		color: '#DEE8E8', // equivalent of .bg-yellow-500
	},
	{
		label: 'End to Wheels Out',
		type: 'wheels_out',
		color: '#EAF0F0', // equivalent of .bg-yellow-500
	},
];

/** 0-1440 are the minutes in a 24-hour day, this is how the "events" are formatted, by having a starting point and a "minute" duration throughout the day to 1440 */
export const dayStartMinute = 0;
export const dayEndMinute = 1440;
export const hourAxisTicks = [...Array(24)].map((_, i) => i * 60);

/** Sets default zoom to be 6:00 - 24:00 */
export const defaultZoomState: [number, number] = [360, 1440];

interface ColorRange {
	marker: number;
	color: ChartColor;
}

/** Little map-structures to help determine which colors to render in the gauge charts for certain ranges on the Core > Dashboard screen. */
const fcotsColors: ColorRange[] = [
	{
		marker: 60,
		color: 'yellow',
	},
	{
		marker: 90,
		color: 'green',
	},
];

// might want to tie these to the target_turnover in facility settings
const totColors: ColorRange[] = [
	{
		marker: 0,
		color: 'green',
	},
	{
		marker: 30,
		color: 'yellow',
	},
	{
		marker: 40,
		color: 'red',
	},
];

const addonColors: ColorRange[] = [
	{
		marker: 0,
		color: 'green',
	},
	{
		marker: 20,
		color: 'yellow',
	},
	{
		marker: 25,
		color: 'dark-yellow',
	},
	{
		marker: 30,
		color: 'red',
	},
];

const blockUtiliColor: ColorRange[] = [
	{
		marker: 0,
		color: 'red',
	},
	{
		marker: 75,
		color: 'green',
	},
	{
		marker: 85,
		color: 'yellow',
	},
];

export const colorMaps = {
	fcots: fcotsColors,
	tot: totColors,
	addon: addonColors,
	blockUtil: blockUtiliColor,
};

/**
 * Function that makes it easy to get the correct color for indicating to user the state of the metric.
 * @param type 'fcots' | 'tot' | 'addon'
 * @param value the value for the metric
 * @returns ChartColor entry
 */
export function getColorForType(type: keyof typeof colorMaps, value: number): ChartColor | undefined {
	let foundRange: ColorRange = colorMaps[type][0];
	colorMaps[type].forEach((range) => {
		if (value >= range.marker) foundRange = range;
	});
	return foundRange.color;
}
