import { EventType } from 'store';

export interface ChartLegendOption {
	label: string;
	color: string;
	type?: EventType;
}

export interface ChartLegendProps {
	/** The options to render in the legend, filled circle icons next to a label. */
	options: ChartLegendOption[];
}

export function ChartLegend({ options }: ChartLegendProps) {
	return (
		<ul className='lg:flex items-center justify-end'>
			{options.map((option, i) => {
				return (
					<li key={i} className='inline-flex items-center ml-6 text-[0.75em] whitespace-nowrap font-semibold'>
						<span className='material-symbol-sm-fill mr-1' style={{ color: option.color }}>
							circle
						</span>
						<span>{option.label}</span>
					</li>
				);
			})}
		</ul>
	);
}

export default ChartLegend;
