import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import {
	Button,
	createCSV,
	Drawer,
	ExportButton,
	FilterFields,
	Heatmap,
	heatmapColorSchemes,
	LogoOverlay,
	PageHeading,
	Panel,
	ToggleGroup,
	Tooltip,
} from 'components';
import { useAppSelector, useGetRoomsRunningQuery, useSystem } from 'store';
import { useFilters, useToast } from 'context';
import { LoadingIndicator } from 'components/Select/subcomponents';

function HeatmapPage() {
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const { data } = useSystem();
	const current_facility = data?.facilities?.find((f) => f.id === selectedFacility);
	const has_scheduled = current_facility?.has_scheduled;

	const { dateRange, clearFilters, metadata, resetFilters, filtersAreDirty, applyFilters } = useFilters();
	const [showHeatmap, setShowHeatmap] = useState<string[]>(has_scheduled ? ['actual', 'scheduled'] : ['actual']);
	const [exportCsv, setExportCsv] = useState(false);

	const updateShowHeatmap = (option: string) => {
		if (!showHeatmap.includes(option)) {
			setShowHeatmap([...showHeatmap, option]);
		} else {
			setShowHeatmap(showHeatmap.filter((item) => item !== option));
		}
	};

	const styleMap = {
		actual: {
			title: 'Actual Cases',
			colorScheme: 'viridis',
		},
		predicted: {
			title: 'Predicted Cases',
			colorScheme: 'blueish',
		},
		difference: {
			title: 'Scheduled/Actual Difference',
			colorScheme: 'rocket',
		},
		scheduled: {
			title: 'Scheduled Cases',
			colorScheme: 'plasma',
		},
	};

	return (
		<div className='min-h-screen'>
			<div className='flex w-full justify-between'>
				<PageHeading>Heatmap</PageHeading>
				<div className='flex items-center justify-center'>
					<div className='flex text-center bg-blue-500 items-center justify-center h-10 rounded-md mr-2'>
						<span className='text-b3 font-semibold px-6 text-white'>
							{`${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`}
						</span>
					</div>
					<div>
						<Tooltip content={'Actual Cases'}>
							<Button
								sizeX='square'
								sizeY='md'
								variant={showHeatmap.includes('actual') ? 'primary' : 'primary-ghost'}
								className='border-r-0 rounded-none'
								onClick={() => updateShowHeatmap('actual')}
							>
								<span className='material-symbols-outlined'>history</span>
							</Button>
						</Tooltip>
						<Tooltip content={'Scheduled Cases'}>
							<Button
								sizeX='square'
								sizeY='md'
								disabled={!has_scheduled}
								variant={showHeatmap.includes('scheduled') ? 'primary' : 'primary-ghost'}
								className='border-r-0 border-l-[0.01em] rounded-none'
								onClick={() => updateShowHeatmap('scheduled')}
							>
								<span className='material-symbols-outlined'>schedule</span>
							</Button>
						</Tooltip>
						<Tooltip content={'Actual/Scheduled Difference'}>
							<Button
								sizeX='square'
								sizeY='md'
								disabled={!has_scheduled}
								variant={showHeatmap.includes('difference') ? 'primary' : 'primary-ghost'}
								className='border-r-0 border-l-[0.01em] rounded-none'
								onClick={() => updateShowHeatmap('difference')}
							>
								<span className='material-symbols-outlined'>compare</span>
							</Button>
						</Tooltip>
						<Tooltip content={'Forecast'}>
							<Button
								sizeX='square'
								disabled
								sizeY='md'
								variant={showHeatmap.includes('predicted') ? 'primary' : 'primary-ghost'}
								className='mr-2 rounded-none border-l-[0.01em]'
								onClick={() => updateShowHeatmap('predicted')}
							>
								<span className='material-symbols-outlined'>robot_2</span>
							</Button>
						</Tooltip>
					</div>
					<Drawer
						metadata={metadata}
						filtersAreDirty={filtersAreDirty}
						trigger={
							<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
								<span className='material-symbol'>filter_alt</span>
								Filters
							</Button>
						}
						quickActions={[
							{
								icon: 'undo',
								onClick: resetFilters,
								tooltipText: 'Discard unapplied filter changes',
								disabled: !filtersAreDirty,
							},
							{
								icon: 'restart_alt',
								onClick: clearFilters,
								tooltipText: 'Reset filters to default',
								disabled: !metadata.saved_at,
							},
						]}
						actionButtons={[
							{
								onClick: applyFilters,
								children: 'Apply',
								disabled: !filtersAreDirty,
							},
							{
								// eslint-disable-next-line @typescript-eslint/no-empty-function
								onClick: () => {},
								children: 'Views',
								disabled: false,
							},
						]}
					>
						<FilterFields
							fields={[
								'dateRange',
								'daysOfWeek',
								'rooms',
								'serviceLines',
								'addOns',
								'encounterTypes',
								'surgeons',
								'percentile',
								'heatmapMethod',
								'turnoverConcurrency',
								'turnoverTimeThreshold',
							]}
							dateRangeLimit={1}
							extendEndDate={has_scheduled}
						/>
					</Drawer>
					<div>
						<Button
							sizeX='md'
							sizeY='md'
							variant='primary'
							onClick={() => {
								setExportCsv(true);

								// setting back to false, if user clicks again
								setTimeout(() => {
									setExportCsv(false);
								}, 1000);
							}}
						>
							Export CSV
						</Button>
					</div>
				</div>
			</div>

			{/* <Panel
				title='Concurrent Rooms Running'
				tooltipContent={
					'Use this visualization to see how many rooms are running concurrently by hour of day and day of week. This can be helpful in determining the number of blocks, staff resources, or anesthesiology resources required during any point in the day.'
				}
				goToHelpID='heatmap'
				headerContentRight={
					
				}
			> */}
			<div className='flex mb-4 gap-4 justify-start w-full'>
				{showHeatmap.slice(0, 2).map((option, i) => (
					<MinimalHeatmap
						key={i}
						title={styleMap[option as unknown as keyof typeof styleMap].title}
						type={option}
						export_csv={exportCsv}
						showFullYAxisName={showHeatmap.length === 1 ? true : undefined}
						customPadding={showHeatmap.length === 1 ? 4 : undefined}
						colorScheme={styleMap[option as unknown as keyof typeof styleMap].colorScheme as heatmapColorSchemes}
					/>
				))}
			</div>
			<div className='flex gap-4 justify-start'>
				{showHeatmap.slice(2, 4).map((option, i) => (
					<MinimalHeatmap
						key={i}
						title={styleMap[option as unknown as keyof typeof styleMap].title}
						type={option}
						showFullYAxisName={showHeatmap.length === 1 ? true : undefined}
						customPadding={showHeatmap.length === 1 ? 4 : undefined}
						export_csv={exportCsv}
						colorScheme={styleMap[option as unknown as keyof typeof styleMap].colorScheme as heatmapColorSchemes}
					/>
				))}
			</div>

			{/* </Panel> */}
		</div>
	);
}

export function MinimalHeatmap(props: {
	type?: string;
	title: string;
	colorScheme: heatmapColorSchemes;
	export_csv?: boolean;
	showFullYAxisName?: boolean;
	customPadding?: number;
	filter_id?: number;
}) {
	const { selectedFacility } = useAppSelector((state) => state.userState);
	// Filters
	const {
		dateRange,
		daysOfWeek,
		rooms,
		addOns,
		serviceLines,
		heatmapMethod,
		encounterTypes,
		surgeons,
		percentile,
		turnoverConcurrency,
		turnoverTimeThreshold,
		currentPageLoaded,
		clearFilters,
		metadata,
		resetFilters,
		filtersAreDirty,
		applyFilters,
	} = useFilters();

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/heatmap' || currentPageLoaded === '/heatmap-compare') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const requestObject =
		props.filter_id !== undefined
			? { facility_id: selectedFacility, filter_id: props.filter_id, type: props.type }
			: {
					facility_id: selectedFacility,
					filters: {
						days_of_week: daysOfWeek?.applied,
						rooms: rooms?.applied,
						add_ons: addOns?.applied,
						service_lines: serviceLines?.applied,
						surgeons: surgeons?.applied,
						encounter_types: encounterTypes?.applied,
						start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
						end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
						percentile: percentile?.applied,
						heatmap_method: heatmapMethod?.applied.name,
						turnover_concurrency: turnoverConcurrency?.applied,
						turnover_threshold: turnoverTimeThreshold?.applied,
					},
					type: props.type,
			  };

	const {
		data: roomsRunningOverview,
		isFetching,
		isError,
		error,
	} = useGetRoomsRunningQuery(requestObject, {
		skip: skipRequest,
	});

	const isSoloHeatmap = props.customPadding !== undefined && props.showFullYAxisName !== undefined;

	// preprocess the data for exporting
	const [exportData, setExportData] = useState<{ day_of_week?: string | undefined }[] | undefined>([]);

	useEffect(() => {
		const tempexportData = roomsRunningOverview?.data.map((group) => {
			const rooms_running_values = { day_of_week: group.y };
			const hour_of_day_values = group.x.map((v, index) => {
				return v;
			});
			Object.assign(rooms_running_values, hour_of_day_values);
			return rooms_running_values;
		});
		setExportData(tempexportData);
	}, [roomsRunningOverview?.data]);

	useEffect(() => {
		if (props.export_csv) {
			createCSV(exportData ?? [], 'heatmap_' + props.type + '_');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.export_csv]);

	return (
		<div className={isSoloHeatmap ? `w-full` : 'w-1/2'}>
			{isFetching || skipRequest ? (
				<div className='bg-gray-50 flex rounded-md mt-5 h-60'>
					<div className='m-auto w-full flex justify-center'>
						<LoadingIndicator />
					</div>
				</div>
			) : roomsRunningOverview?.data.length === 0 && !(isFetching || skipRequest) ? (
				<div className='bg-gray-50 flex mt-5 h-60 rounded-md'>
					<div className='m-auto w-full flex justify-center text-gray-300'>No Data</div>
				</div>
			) : (
				<Heatmap
					title={props.title}
					showFullYAxisName={props.showFullYAxisName}
					colorScheme={props.colorScheme}
					customCellPadding={props.customPadding}
					datumFormat={(datum) => String(Math.round(parseFloat(String(datum))))}
					axisLabels={{ x: 'Hour of Day', y: 'Day of Week' }}
					data={isError ? [] : roomsRunningOverview?.data ?? []}
					emptyMessage='This heatmap has no data to display based on the provided filters.'
				/>
			)}
		</div>
	);
}

export { HeatmapPage as Heatmap, HeatmapPage as default };
