import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';

import { Button, MultiSelect, Select, TextField, ToggleGroup } from 'components';
import {
	getQueryError,
	handleApiResponse,
	setBlockName,
	setSurgeons,
	useAppDispatch,
	useAppSelector,
	useGetBlockExistsMutation,
	useGetSurgeonsQuery,
	useUpdateSystemMutation,
} from 'store';
import { useToast } from 'context';
import { Healthsystem } from 'models';
import { cms_designations, ehr_systems, requireValidFieldValue, us_states } from 'utils';

import type { SubmitHandler } from 'react-hook-form';

/**
 * Block settings form structures
 */
const healthsystemFormSchema = yup.object({
	id: yup.number(),
	name: yup
		.string()
		.trim()
		.required('A name is required to create a Health System')
		.test('valid', 'This field value is not valid.', requireValidFieldValue)
		.max(100, 'Health System name cannot be more than 100 characters.'),
	city: yup.string().nullable(),
	zip: yup.number().nullable(),
	ehr: yup.string().nullable(),
	cms_designation: yup.string().nullable(),
	number_of_beds: yup.number().nullable(),
	street_address: yup.string().nullable(),
	state: yup.string().nullable(),
});

type HealthsystemFormInputs = yup.InferType<typeof healthsystemFormSchema>;

export function HealthSystemConfigAccordion({ system }: { system: Healthsystem }) {
	const defaultHealthsystemFormInputValues = {
		id: system.id ?? 0,
		name: system.name,
		city: system.city,
		state: system.state,
		zip: system.zip,
		street_address: system.street_address,
		ehr: system.ehr ?? '',
		cms_designation: system.cms_designation,
		number_of_beds: system.number_of_beds ?? 0,
	};

	const { createToast } = useToast();

	// form state
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isDirty },
	} = useForm<HealthsystemFormInputs>({ resolver: yupResolver(healthsystemFormSchema) });

	const [updateSystem] = useUpdateSystemMutation();
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	const onSubmit: SubmitHandler<HealthsystemFormInputs> = async (formData) => {
		setButtonLoading(true);
		const res = await updateSystem({ ...formData, id: system.id });
		handleApiResponse(res, {
			success: (payload) => {
				createToast({
					title: 'Health System Updated!',
					variant: 'success',
				});
				setButtonLoading(false);
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
				setButtonLoading(false);
			},
		});
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
				<p className='text-black mb-3 ml-3'>System Information</p>
				<div className='flex flex-col bg-blue-50 p-4 mb-5 border border-blue-500 rounded-md'>
					<div className='flex gap-4 flex-wrap rounded-md w-5/6'>
						<TextField
							label='Health System Name'
							defaultValue={defaultHealthsystemFormInputValues.name}
							errorMessage={errors.name?.message}
							{...register('name')}
							sizeX='lg'
						/>
						<TextField
							label='Street Address'
							defaultValue={defaultHealthsystemFormInputValues.street_address ?? undefined}
							errorMessage={errors.street_address?.message}
							{...register('street_address')}
							sizeX='lg'
						/>
						<TextField
							label='City'
							defaultValue={defaultHealthsystemFormInputValues.city}
							errorMessage={errors.city?.message}
							{...register('city')}
							sizeX='lg'
						/>
						<div className='mr-3'>
							<Select
								label='State'
								sizeY='sm'
								sizeX='lg'
								defaultValue={[
									{ label: defaultHealthsystemFormInputValues.state, value: defaultHealthsystemFormInputValues.state },
								]}
								errorMessage={errors.state?.message}
								options={us_states.map((state) => ({ label: state, value: state }))}
								onChange={(option) => setValue('state', option?.label ?? '')}
							/>
						</div>

						<TextField
							type='number'
							label='Zip Code'
							defaultValue={defaultHealthsystemFormInputValues.zip ?? undefined}
							errorMessage={errors.zip?.message}
							{...register('zip')}
							sizeX='lg'
						/>
						<Select
							label='EHR'
							sizeY='sm'
							sizeX='lg'
							defaultValue={{ label: defaultHealthsystemFormInputValues.ehr, value: defaultHealthsystemFormInputValues.ehr }}
							errorMessage={errors.ehr?.message}
							options={ehr_systems.map((system) => ({ label: system, value: system }))}
							onChange={(option) => setValue('ehr', option?.label ?? '')}
						/>
						<div className='mr-3'>
							<Select
								label='CMS Designation'
								sizeY='sm'
								defaultValue={{
									label: defaultHealthsystemFormInputValues.cms_designation,
									value: defaultHealthsystemFormInputValues.cms_designation,
								}}
								sizeX='lg'
								errorMessage={errors.cms_designation?.message}
								options={cms_designations.map((cms) => ({ label: cms, value: cms }))}
								onChange={(option) => setValue('cms_designation', option?.label ?? '')}
							/>
						</div>
						<TextField
							type='number'
							defaultValue={defaultHealthsystemFormInputValues.number_of_beds}
							label='Number of Beds'
							errorMessage={errors.number_of_beds?.message}
							{...register('number_of_beds')}
							sizeX='lg'
							sizeY='sm'
						/>
					</div>
					<div className='flex justify-end mr-4'>
						<Button variant='primary' sizeX='md' sizeY='md' disabled={!isDirty} isWorking={buttonLoading} type='submit'>
							Save
						</Button>
					</div>
				</div>
			</form>
		</>
	);
}
